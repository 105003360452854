export default class buttonTextUnit {

  getButtonClass = (props) => {
    let buttonClass = 'buttonText';

    if (props.type) buttonClass += ' buttonText_' + props.type;
    else buttonClass += ' buttonText_secondary';
    buttonClass += ' ' + props.className;

    if (props.disabled) {
      buttonClass += " disabled";
    } else {
      buttonClass += " buttonText_enabled";
    }



    return buttonClass;
  }
}