import React, { createContext, useReducer } from 'react'

const initialState = {
  orderListTableData: null,
  activeFilter: false,
  currentPage: 1,
  firtsLoadTableData: true
};

const orderContext = createContext({
  listStore: initialState
});


const reduser = (listStore, action) => {
  switch (action.type) {
    case "setOrderTableDate":
      return { ...listStore, orderListTableData: action.payload };
    case "setActiveFilter":
      return { ...listStore, activeFilter: action.payload };
    case "clearTableData":
      return { ...listStore, orderListTableData: null, firtsLoadTableData: false };
    case "setCurrentPage":
      return { ...listStore, currentPage: action.payload, firtsLoadTableData: false };
    default:
      return listStore;
  }
};

const OrderListStore = (props) => {
  const [listStore, dispatch] = useReducer(reduser, initialState);
  const events = {
    setOrderTableDate: data => dispatch({ type: "setOrderTableDate", payload: data }),
    setActiveFilter: (value) => dispatch({ type: "setActiveFilter", payload: value }),
    clearTableData: () => dispatch({ type: "clearTableData", payload: null }),
    setCurrentPage: (page) => dispatch({ type: "setCurrentPage", payload: page })
  };
  return (
    <orderContext.Provider value={{ ...events, listStore }}>{props.children}</orderContext.Provider>
  )
}

export { OrderListStore, orderContext }
