import React from 'react'
import InfoPage from '../InfoPage/InfoPage'
import { useRouteMatch, Link } from 'react-router-dom';
import ButtonText from '../../components/buttons/ButtonText/ButtonText';

const OrderWasCreated = () => {
  const match = useRouteMatch();
  const num = match.params.id;

  const content = (
    <div className="orderWasCreated">
      <div>
        <h2 className="orderWasCreated_title">Заказ <span>№{num}</span> успешно создан!</h2>
        <div className="orderWasCreated_buttons">
          <Link className="orderWasCreated_button" to="/order-list">
            <ButtonText type='primary' value="К списку" />
          </Link>
          <Link className="orderWasCreated_button" to={`/order-edit/` + num + "/copy"} >
            <ButtonText value="Продублировать" />
          </Link>
          <Link className="orderWasCreated_button" to="/order-new">
            <ButtonText value="Новый заказ" />
          </Link>
        </div>
      </div>
    </div >
  );
  return (
    <InfoPage content={content} />
  )
}

export default OrderWasCreated
