import React, { useEffect, useState } from 'react'
import OrderMobileItem from './OrderMobileItem';
import convertDataService from '../../../services/conversionData.service';

const OrderMobileTable = (props) => {
  const cDS = new convertDataService();
  const [classOrderMobileTableBtnUp, setClassOrderMobileTableBtnUp] = useState('orderMobileTable_btnUp mobileData hidden');

  const scrollListener = () => {
    if (!cDS.thisIsDesctopVersion()) {
      getClassOrderMobileTableBtnUp();
    }
  }

  useEffect(() => {
    if (!cDS.thisIsDesctopVersion()) {
      window.addEventListener("scroll", scrollListener);
      return () => {
        window.removeEventListener("scroll", scrollListener);
      };
    }
  });

  if (cDS.thisIsDesctopVersion()) {
    return <></>;
  }

  const getClassOrderMobileTableBtnUp = () => {
    const visibleClass = "orderMobileTable_btnUp mobileData";
    const hiddenClass = "orderMobileTable_btnUp mobileData hidden";
    const posVisible = 200; //px
    if (window.pageYOffset > posVisible && classOrderMobileTableBtnUp !== visibleClass) {
      setClassOrderMobileTableBtnUp(visibleClass);
    } else if (window.pageYOffset <= posVisible && classOrderMobileTableBtnUp !== hiddenClass)
      setClassOrderMobileTableBtnUp(hiddenClass);
  }

  const handleButtonUpClick = (e) => {
    e.preventDefault();
    let w = window.pageYOffset;
    let start = null;
    let t = -1 * window.pageYOffset; //насколько нужно прокрутить вниз
    const V = .3; //скрость, чем ниже, тем больше
    const step = (time) => {
      if (start === null) start = time;
      let progress = time - start;
      let r = (t < 0 ? Math.max(w - progress / V, w + t) : Math.min(w + progress / V, w + t));
      window.scrollTo(0, r);
      if (r !== w + t) {
        requestAnimationFrame(step)
      } else {
      }
    }
    requestAnimationFrame(step);
  }


  return (
    <>
      <button className={classOrderMobileTableBtnUp} onClick={handleButtonUpClick}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M4.465 366.475l7.07 7.071c4.686 4.686 12.284 4.686 16.971 0L224 178.053l195.494 195.493c4.686 4.686 12.284 4.686 16.971 0l7.07-7.071c4.686-4.686 4.686-12.284 0-16.97l-211.05-211.051c-4.686-4.686-12.284-4.686-16.971 0L4.465 349.505c-4.687 4.686-4.687 12.284 0 16.97z" />
        </svg>
      </button>
      <div className="orderMobileTable mobileData">
        {props.tableData ? props.tableData.rows.map(row => <OrderMobileItem key={cDS.getRandomKey()} row={row} />) : <></>}
      </div>
    </>
  )
}

export default OrderMobileTable
