export default class editOrderUnit {
  getTextPushButton = (stateOrder) => {
    if (stateOrder === "copy") {
      return "Создать";
    } else {
      return "Сохранить"
    }
  }

  getTextOrderTitle = (idorders, manualnumber, date, status) => {
    let answer = 'Заказ';

    if (status === "copy") {
      return "Заказ";
    }


    if (manualnumber) {
      answer += ' №' + manualnumber;
    } else {
      answer += ' ( №' + idorders + " )";
    }

    answer += " от " + date;

    return answer;
  }

  getIsDisabledByView = (stateOrder, canceled) => {
    if (stateOrder === "view" || canceled)
      return true;
    if (stateOrder === "copy")
      return false;
    return false;
  }

  getGuidesId = (guides) => {
    let guidesId = [];
    for (let i = 0; i < guides.length; i++) {
      guidesId = [...guidesId, guides[i].id];
    }
    return guidesId;
  }

  getMenegersId = (menegers) => {
    let menegersId = [];
    for (let i = 0; i < menegers.length; i++) {
      menegersId = [...menegersId, menegers[i].id];
    }
    return menegersId;
  }

  getCityFromDb = (store, orderData) => {
    let cityFromDb = null;
    if (store.allCities.rows) {
      cityFromDb = store.allCities.rows.map(el => {
        if (orderData.city === el.id) {
          return el;
        }
        return null
      }).filter(el => el !== null)[0];
    }
    return cityFromDb;
  }

  getNewRequest = (order, request, stateOrder) => {
    if (stateOrder === "edit")
      return request.addOrder(order, 'put');
    if (stateOrder === "copy")
      return request.addOrder(order, 'post');
    return "bad stateOrder. StateOrder: " + stateOrder;
  }

}