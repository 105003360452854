import React from 'react'
import Select, { components } from 'react-select'
import selectMainUnit from './selectMain.unit';
import { ValidationText } from '../../../services/checkValidation.servise';


const MultiValueRemove = props => {


  const item = {
    className: "selectMain_multiValueRemove"
  }
  return (
    <components.MultiValueRemove innerProps={item} {...props}>
      X
    </components.MultiValueRemove>
  );
};

const MultiValueLabel = ({ children, data }) => {
  return <button className="selectMain_button" onClick={() => {
    data.onClick(data);
  }}>
    {data.value}
  </button>
};

const SelectMain = (props) => {
  const unit = new selectMainUnit();
  const options = unit.getOptions(props);

  let selfClass = unit.getSelftClass(props.className);

  const handleInputChange = (value) => {
    if (props.personalValue) {
      const newItem = {
        value: value,
        label: value,
        name: value
      }
      handleChange(newItem);
    }
  }



  const handleChange = (el) => {
    if (props.changeValue) {
      props.changeValue(el);
    }
  }



  const getCurrentSelect = () => {
    const selectProps = {
      onInputChange: handleInputChange,
      defaultValue: defaultValue || defaultValue === 0 ? options[defaultValue] : null,
      isClearable: true,
      components: { MultiValueLabel, MultiValueRemove },
      isSearchable: isSearchable,
      options: options,
      value: props.value,
      onChange: handleChange,
      isMulti: props.isMulti,
      isDisabled: isDisabled,
      placeholder: placeholder
    }
    return (
      <Select
        {...selectProps}
      />
    )
    // if (props.isCreatable) {
    //   return (
    //     <CreatableSelect
    //       {...selectProps}
    //     />
    //   )
    // } else {
    //   return (
    //     <Select
    //       {...selectProps}
    //     />
    //   )
    // }
  }

  const defaultValue = unit.getDefaultValue(props, options);

  let isSearchable = true;
  if (props.isSearchable === false) {
    isSearchable = props.isSearchable;
  }

  let isDisabled = false;
  if (props.isDisabled) {
    selfClass += ' disabled'
    isDisabled = props.isDisabled
  }

  selfClass = unit.getView(selfClass, props.view);

  const placeholder = unit.getPlaceholder(props.placeholder);

  const currentSelect = getCurrentSelect();

  return (
    <div className={selfClass} >
      {currentSelect}
      <ValidationText viewValidation={props.viewValidation} validation={props.validation} value={props.value} />
    </div >
  )
}

export default SelectMain
