import React from 'react'

const ValidationText = (props) => {
    if (props.viewValidation && props.validation && !props.validation(props.value).validated) {
        return <p className="mainInput_validatedText">{props.validation(props.value).text}</p>
    }
    return null;
}

export default class checkValidationServise {

    checkValidCity = (city) => {
        if (!city) {
            return { validated: false, text: "*Необходимо указать город" }
        }
        return { validated: true, text: "" }
    }

    checkValidDate = (date) => {
        if (date) {
            return { validated: true, text: "" }
        } else {
            return { validated: false, text: "*Необходимо указать дату" }
        }
    }

    checkValidPlace = (place, message = "*Необходимо указать место") => {
        if (!place) {
            return { validated: false, text: message }
        }
        return { validated: true, text: "" }
    }

    checkValidNumByDevise = (number) => {
        if (parseInt(number) > 0) {
            return { validated: true, text: "" }
        } else {
            return { validated: false, text: "*Укажите число больше нуля" }
        }
    }

    checkValidMember = (members) => {
        if (members && parseInt(members.length) > 0) {
            return { validated: true, text: "" }
        } else {
            return { validated: false, text: "*Добавьте хотябы одного сотрудника" }
        }
    }
}

export { ValidationText };