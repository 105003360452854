import React, { useState, useContext } from "react";
import convertDataService from "../../../services/conversionData.service";
import { Redirect } from "react-router-dom";
import { Ctx } from "../../../Store";
import { Tooltip } from "@material-ui/core";
import { orderContext } from "../OrderListStore";
import { OrderTableTriangle as Triangle } from "./OrderTableTriangle";
import rAPService from "../../../services/requestsApiDatabase.service";

const OrderTable = props => {
  const cDS = new convertDataService();
  const { store } = useContext(Ctx);
  const request = new rAPService({ url: store.apiUrl });

  const { clearTableData } = useContext(orderContext);
  const tableFields = cDS.getOrderTableFields();
  const tableData = props.tableData;
  const activeFilter = props.activeFilter;
  const [redirect, setRedirect] = useState();
  const setOrderSortedByColumn = props.setOrderSortedByColumn;
  const orderSortedByColumn = props.orderSortedByColumn;
  const updateEarlyTableData = props.updateEarlyTableData;
  let tableClass = "table desktopData";

  if (document.documentElement.clientWidth < 631)
    return <></>;


  if (activeFilter)
    tableClass += " disabled";

  if (!tableData || !tableData.rows) {
    return (
      <div className="notFound">
        Записей не обнаружено
      </div >
    )
  }

  const sortByColumn = (columnClicked) => {
    const { type, column } = orderSortedByColumn;
    if (column === columnClicked) {
      if (type === "asc") {
        setOrderSortedByColumn({
          column: columnClicked,
          type: "desc"
        })
      } else {
        setOrderSortedByColumn({
          column: columnClicked,
          type: "asc"
        })
      }
    } else {
      setOrderSortedByColumn({
        column: columnClicked,
        type: "asc"
      })
    }
    clearTableData();
    updateEarlyTableData();
  }

  const hideColumnBySettings = (key) => {
    if (!store.user.user_settings.show_sum && key === "sum") {
      return true;
    }
    if (!store.user.user_settings.show_payment && key === "paid") {
      return true;
    }
    if (store.user.role > 1 && key === "client_name") {
      return true;
    }
    return false;
  }

  const rowClick = (id, e) => {
    if (!activeFilter) {
      let tdClass = e.target.className.baseVal || e.target.className;
      if (tdClass.baseVal === "") {
        tdClass = e.target.parentNode.className.baseVal;
      }
      if (tdClass.indexOf("table_row_copy") > -1) {
        setRedirect(id + "/copy");
      }
      else if (tdClass.indexOf("table_row_trash") > -1) {
        if (window.confirm("Вы уверены что хотите удалить заказ навсегда?")) {
          const answer = request.deleteOrder(id);
          answer.then(el => {
            el.json().then(
              data => {
                if (data && !data.status) {
                  if (data.error === "ERROR: Deleting is not allowed,  you can delete only canceled orders ") {
                    alert("Вы можете удалять только отменные заказы");
                  } else {
                    alert(data.error);
                  }
                } else {
                  clearTableData();
                  alert("Заказ удален");
                }
              }
            )
          }
          )
        }
      } else {
        setRedirect(id);
      }
    }
  };

  if (redirect) return <Redirect to={`/order-edit/${redirect}`} />

  const createTableTitles = () => {
    let rowKeys = null;
    if (tableData.rows[0])
      rowKeys = Object.keys(tableData.rows[0]);
    const allTitlesKeys = Object.keys(tableFields);

    return (
      <>
        <th></th>
        {
          allTitlesKeys.map((key) => {
            let trianlge = null;
            const tableTitleClass = "closeFilter table_th table_" + key;
            let onClick = () => { };
            if (store.user.role > 1) {
              switch (key) {
                case "idorders":
                  onClick = () => { sortByColumn("date") };
                  trianlge = < Triangle orderSortedByColumn={orderSortedByColumn} column="date" />
                  break;
                case "delivery_time":
                  trianlge = < Triangle orderSortedByColumn={orderSortedByColumn} column="delivery_time" />
                  onClick = () => { sortByColumn("delivery_time") };
                  break;
                default:
                  break;
              }
            }
            if (hideColumnBySettings(key)) {
              return null;
            }
            if (rowKeys && rowKeys.indexOf(key) > -1) {
              return <th onClick={onClick} className={tableTitleClass} key={cDS.getRandomKey()} align="left" valign="top">
                <span>
                  {tableFields[key]}
                </span>
                {trianlge}
              </th>
            }
            return null;
          })}
      </>
    );
  };

  const getTableItemClass = (key, hoverClass = "") => {
    let column = orderSortedByColumn.column;
    if (column === "date") {
      column = "idorders";
    }
    let tempClass = "closeFilter table_td table_" + key + " table_td--" + hoverClass;
    if (key === column) {
      tempClass += " table_td-colored"
    }
    return tempClass;
  }


  const createTableRows = () => {
    let rowKeys = null;
    if (tableData.rows[0])
      rowKeys = Object.keys(tableData.rows[0]);
    const allTitlesKeys = Object.keys(tableFields);
    return tableData.rows.map(row => {
      const orderStatus = cDS.getStatusByOrderData(row);
      let rowClassName = "table_rowsBorder--" + orderStatus.class;
      rowClassName += " table_rowsBackground table_rowsBackground--" + orderStatus.class;
      return <tr className={rowClassName} key={cDS.getRandomKey()} onClick={(e) => {
        rowClick(row.idorders, e);
      }}>
        <td className="table_row_copy table_td table_rowWithIcon"><i className="fal fa-copy table_row_copy table_rowWithIcon_icon"></i></td>
        {
          allTitlesKeys.map(key => {
            if (hideColumnBySettings(key)) {
              return null;
            }
            let tableItemClass = getTableItemClass(key, orderStatus.class);
            if (rowKeys) {
              if (rowKeys.indexOf(key) > -1) {
                let tableItemContent = row[key];
                switch (key) {
                  case "idorders":
                    let idNum = row[key];
                    if (row["manualnumber"])
                      idNum = row["manualnumber"];
                    tableItemContent = "№" + idNum + " от " + cDS.parseSepareteDate(row["date"], '.', 'DateDMY');
                    break;
                  case "manualnumber":
                    if (row[key].length > 19)
                      tableItemContent = row[key].slice(0, 19) + "...";
                    break;
                  case "client_name":
                    if (row[key].length > 28)
                      tableItemContent = row[key].slice(0, 28) + "...";
                    break;
                  case "guide":
                    if (row[key].length > 50)
                      tableItemContent = row[key].slice(0, 50) + "...";
                    break;
                  case "delivery_place":
                    if (row[key].length > 70)
                      tableItemContent = row[key].slice(0, 70) + "...";
                    break;
                  case "return_place":
                    if (row[key].length > 70)
                      tableItemContent = row[key].slice(0, 70) + "...";
                    break;
                  case "recivers":
                    tableItemContent = row[key] + "/" + row["transmitters"];
                    break;
                  case "delivery_time":
                    tableItemContent = cDS.parseSepareteDate(row[key], '.', 'DateDMYHM');
                    break;
                  case "return_time":
                    tableItemContent = cDS.parseSepareteDate(row[key], '.', 'DateDMYHM');
                    break;
                  case "sum":
                    tableItemContent += "₽";
                    break;
                  case "paid":
                    if (tableItemContent) tableItemContent = "+";
                    else tableItemContent = "-";
                    break;
                  default:
                    break;
                }
                if (hideColumnBySettings(key)) {
                  return null;
                }
                return (
                  <Tooltip key={cDS.getRandomKey()} title={orderStatus.text}>
                    <td valign="top" className={tableItemClass} key={cDS.getRandomKey()} align="left">
                      <span>
                        {tableItemContent}
                      </span>
                    </td>
                  </Tooltip>

                )
              }
            }
            return null;
          })
        }
        <td className="table_td table_row_trash table_rowWithIcon">
          <i className="fal fa-trash table_row_trash table_rowWithIcon_icon"></i>
        </td>
      </tr>
    });
  };

  const tableTitles = createTableTitles();
  const tableRows = createTableRows();




  return (
    <table className={tableClass}>
      <thead>
        <tr>
          {tableTitles}
        </tr>
      </thead>
      <tbody>
        {tableRows}
      </tbody>
    </table>
  );
};

export default OrderTable;
