import React from 'react'

const OrderTableTriangle = (props) => {
  const getSelfClass = () => {
    let tempClass = "table_triangle";
    if (props.column === props.orderSortedByColumn.column) {
      tempClass += " table_triangle--active";
      if (props.orderSortedByColumn.type === "desc") {
        tempClass += " table_triangle--desc";
      }

    } else {
      tempClass += " table_triangle--disabled";
    }
    return tempClass;
  }

  const selfClass = getSelfClass();


  return (
    <svg className={selfClass} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M329.6 24c-18.4-32-64.7-32-83.2 0L6.5 440c-18.4 31.9 4.6 72 41.6 72H528c36.9 0 60-40 41.6-72l-240-416z" /></svg>
  )
}

export { OrderTableTriangle }
