import React, { useState, useContext } from "react";
import ButtonText from "../../../components/buttons/ButtonText/ButtonText";
import ButtonIconUser from "../../../components/buttons/ButtonIconUser/ButtonIconUser";
import FormNewMember from "../../../components/FormNewMember/FormNewMember";
import { Ctx } from "../../../Store";
import rAPService from "../../../services/requestsApiDatabase.service";
import Loader from "react-spinners/RingLoader";
import { css } from "@emotion/core";
import convertDataService from "../../../services/conversionData.service";
import TextArea from "../../../components/TextArea/TextArea";
import SelectMain from "../../../components/selects/SelectMain/SelectMain";
import checkValidationServise from "../../../services/checkValidation.servise";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const SecondStep = props => {
  const { store } = useContext(Ctx);
  const request = new rAPService({ url: store.apiUrl });
  const cDS = new convertDataService();
  const validService = new checkValidationServise();

  //функции и данные формы
  const [isActiveForm, setIsActiveForm] = useState(false);
  const [formMemberType, setFormMemberType] = useState('');
  const [formTitle, setFormTitle] = useState('');
  const [formMemberName, setFormMemberName] = useState('');
  const [formPhone, setFormPhone] = useState('');
  const [formEmail, setFormEmail] = useState('');
  const [formMemberId, setFormMemberId] = useState();
  const [members, setMembers] = useState();

  //данные полей первого шага
  const orderGuidesArray = props.secondStepValues.orderGuidesArray;
  const orderMenegersArray = props.secondStepValues.orderMenegersArray;
  const orderComment = props.secondStepValues.orderComment;

  //функции полей первого шага
  const setOrderGuidesArray = props.secondStepFunctions.setOrderGuidesArray;
  const setOrderMenegersArray = props.secondStepFunctions.setOrderMenegersArray;
  const setOrderComment = props.secondStepFunctions.setOrderComment;
  const pushOrder = props.secondStepFunctions.pushOrder;



  //отображение валидации
  const viewSecondStepValidation = props.secondStepValues.viewSecondStepValidation;
  const checkValidSecondStep = props.secondStepFunctions.checkValidSecondStep;


  const formData = {
    client_id: store.user.user_settings.client_id,
    formMemberType,
    formTitle,
    formMemberName,
    formPhone,
    formEmail,
    formMemberId,
    orderGuidesArray,
    orderMenegersArray
  }

  const handleChangePhone = (phone) => {
    const newPhone = cDS.wrapPhoneByMask(phone);
    if (newPhone || newPhone === "")
      setFormPhone(newPhone);
  }


  const clientId = store.user.user_settings.client_id;

  const updateMembers = () => {
    request.getGuidesAndMenegers(clientId).then(el => setMembers(el));
  }

  const clearMemberForm = () => {
    setFormTitle('Форма сотрудника');
    setFormMemberName('');
    setFormPhone('');
    setFormEmail('');
    setFormMemberId('');
  }

  const openFormNewGuide = () => {
    clearMemberForm();
    setFormTitle('Добавить гида');
    setFormMemberType(true);
    setIsActiveForm(true);
  };

  const openFormNewManager = () => {
    clearMemberForm();
    setFormTitle('Добавить менеджера');
    setFormMemberType(false);
    setIsActiveForm(true);
  };

  const memberClick = (member) => {
    setFormMemberType(member.guide)
    setFormTitle('Редактировать ' + member.name);
    setFormMemberName(member.name);
    handleChangePhone(member.phone_num);
    setFormEmail(member.email);
    setFormMemberId(member.id);
    setIsActiveForm(true);
  }

  if (!members) {
    updateMembers();
    return <Loader css={override} size={200} color={"blue"} loading={true} />
  }

  const formChangeData = {
    setFormMemberName,
    handleChangePhone,
    setFormEmail,
    setMembers,
    setOrderGuidesArray,
    setOrderMenegersArray,
    memberClick
  }

  let buttonErrorAnimation = false;
  if (!checkValidSecondStep()) {
    buttonErrorAnimation = true;
  }

  return (
    <div className="createOrder_secondStep">
      <FormNewMember formData={formData} formChangeData={formChangeData} active={isActiveForm} changeActive={setIsActiveForm} />
      <div className="createOrder_form-item">
        <div className="createOrder_form-item--title">
          <i className="fal fa-user-ninja red"></i>
          <h3>Гид</h3>
        </div>
        <div className="createOrder_form-secondStepItem">
          <SelectMain
            isMulti={true}
            value={orderGuidesArray}
            changeValue={setOrderGuidesArray}
            items={members.guides}
            onItemClick={memberClick}
            className="createOrder_multiSelect"
            validation={validService.checkValidMember}
            viewValidation={viewSecondStepValidation}
          />
          <ButtonIconUser action={openFormNewGuide} />
        </div>
      </div>
      <div className="createOrder_form-item">
        <div className="createOrder_form-item--title">
          <i className="fal fa-user-tie blue"></i>
          <h3>Менеджер</h3>
        </div>
        <div className="createOrder_form-secondStepItem">
          <SelectMain
            isMulti={true}
            value={orderMenegersArray}
            changeValue={setOrderMenegersArray}
            items={members.menegers}
            onItemClick={memberClick} className="createOrder_multiSelect"
            validation={validService.checkValidMember}
            viewValidation={viewSecondStepValidation}
          />
          <ButtonIconUser action={openFormNewManager} />
        </div>
      </div>
      <div className="createOrder_form-item">
        <div className="createOrder_form-item--title">
          <i className="fal fa-puzzle-piece yellow"></i>
          <h3>Дополнительно</h3>
        </div>
        <div className="createOrder_form-secondStepItem">
          <TextArea text={orderComment} changeText={setOrderComment} />
        </div>
      </div>

      <div className="createOrder_secondStep-buttons">
        <ButtonText
          className="createOrder_secondStep-buttons-button"
          onClick={props.previousStep}
          value="Назад"
        />
        <ButtonText
          className="createOrder_secondStep-buttons-button"
          onClick={pushOrder}
          type="primary"
          value="Отправить"
          errrorAnimation={buttonErrorAnimation}
        />
      </div>
    </div>
  );
};

export default SecondStep;
