import React, { useContext } from 'react'
import convertDataService from '../../../services/conversionData.service';
import { orderContext } from '../OrderListStore';

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

const OrderTablePagination = (props) => {
  const cDS = new convertDataService();
  const { listStore, setCurrentPage, clearTableData } = useContext(orderContext);

  if (!cDS.thisIsDesctopVersion())
    return <></>;

  const countAllPages = props.countAllPages;
  const allRowsCount = props.allRowsCount;

  let selfClass = "table_pagination";

  if (props.className) selfClass += " " + props.className;

  const overBack = () => {
    if (listStore.currentPage > 1) {
      setCurrentPage(1);
      clearTableData();
    }
  }

  const back = () => {
    if (listStore.currentPage > 1) {
      setCurrentPage(listStore.currentPage - 1);
      clearTableData();
    }
  }

  const overNext = () => {
    if (listStore.currentPage < countAllPages) {
      setCurrentPage(countAllPages);
      clearTableData();
    }
  }

  const changePag = (v) => {
    props.setRowsCount(v)
    clearTableData();
  }



  const next = () => {
    if (listStore.currentPage < countAllPages) {
      setCurrentPage(listStore.currentPage + 1);
      clearTableData();
    }
  }

  const rowByrows = "Строки: " + (((listStore.currentPage - 1) * props.rowsCount) + 1) + " - " + listStore.currentPage * props.rowsCount + " из " + (allRowsCount);

  const pagItems = [5, +props.rowsCount, 10, 20, 50, 100, 200].filter(onlyUnique).sort((a, b) => a-b)

  return (
    <div className={selfClass}>
      <div className="table_pagination-currentPage">{"Страница: " + listStore.currentPage}</div>
      <div className="table_pagination-select">
        <select style={{
          padding: "2px 10px",
          paddingRight: 2,
          maxHeight: 40,
          marginRight: 10
        }} value={props.rowsCount} onChange={(v) => changePag(v.target.value)}>
          {pagItems.map((el) => <option key={JSON.stringify(el)} value={el}>{el}</option>)}
        </select>
      </div>
      <div className="table_pagination-currentRows desktopData">{rowByrows}</div>
      <div className="table_pagination-overBack">
        <svg onClick={overBack} width="38" height="36" viewBox="0 0 38 36" fill="none" xmlns="http://www.w3.org/2000/svg">
          <ellipse rx="17.7772" ry="17.6873" transform="matrix(-1 0 0 1 18.7226 18.0001)" />
          <path d="M23.9601 11.8078C24.3507 11.4173 24.9838 11.4173 25.3743 11.8078C25.7649 12.1983 25.7649 12.8315 25.3743 13.222L23.9601 11.8078ZM25.3743 13.222L19.3077 19.2887L17.8935 17.8745L23.9601 11.8078L25.3743 13.222Z" />
          <path d="M23.9601 24.1922C24.3507 24.5827 24.9838 24.5827 25.3743 24.1922C25.7649 23.8017 25.7649 23.1685 25.3743 22.778L23.9601 24.1922ZM25.3743 22.778L19.3077 16.7113L17.8935 18.1255L23.9601 24.1922L25.3743 22.778Z" />
          <line x1="1" y1="-1" x2="12" y2="-1" transform="matrix(4.37114e-08 1 1 -4.37114e-08 14.7781 11.5)" stroke="black" strokeWidth="2" strokeLinecap="round" />
        </svg>

      </div>
      <div className="table_pagination-back">
        <svg onClick={back} width="38" height="36" viewBox="0 0 38 36" fill="none" xmlns="http://www.w3.org/2000/svg">
          <ellipse rx="17.7772" ry="17.6873" transform="matrix(-1 0 0 1 18.9999 18.0001)" />
          <path d="M20.2929 11.8682C20.6834 11.4777 21.3166 11.4777 21.7071 11.8682C22.0976 12.2587 22.0976 12.8919 21.7071 13.2824L20.2929 11.8682ZM21.7071 13.2824L15.7071 19.2824L14.2929 17.8682L20.2929 11.8682L21.7071 13.2824Z" fill="black" />
          <path d="M20.2929 24.132C20.6834 24.5226 21.3166 24.5226 21.7071 24.132C22.0976 23.7415 22.0976 23.1083 21.7071 22.7178L20.2929 24.132ZM21.7071 22.7178L15.7071 16.7178L14.2929 18.132L20.2929 24.132L21.7071 22.7178Z" fill="black" />
        </svg>

      </div>
      <div className="table_pagination-next">
        <svg onClick={next} width="37" height="36" viewBox="0 0 37 36" fill="none" xmlns="http://www.w3.org/2000/svg">
          <ellipse cx="18.5001" cy="18.0001" rx="17.7772" ry="17.6873" />
          <path d="M17.2071 11.8682C16.8166 11.4777 16.1834 11.4777 15.7929 11.8682C15.4024 12.2587 15.4024 12.8919 15.7929 13.2824L17.2071 11.8682ZM15.7929 13.2824L21.7929 19.2824L23.2071 17.8682L17.2071 11.8682L15.7929 13.2824Z" fill="black" />
          <path d="M17.2071 24.132C16.8166 24.5226 16.1834 24.5226 15.7929 24.132C15.4024 23.7415 15.4024 23.1083 15.7929 22.7178L17.2071 24.132ZM15.7929 22.7178L21.7929 16.7178L23.2071 18.132L17.2071 24.132L15.7929 22.7178Z" fill="black" />
        </svg>

      </div>
      <div className="table_pagination-next">
        <svg onClick={overNext} width="37" height="36" viewBox="0 0 37 36" fill="none" xmlns="http://www.w3.org/2000/svg">
          <ellipse cx="18.5001" cy="18.0001" rx="17.7772" ry="17.6873" />
          <path d="M13.2625 11.8078C12.872 11.4173 12.2388 11.4173 11.8483 11.8078C11.4578 12.1983 11.4578 12.8315 11.8483 13.222L13.2625 11.8078ZM11.8483 13.222L17.915 19.2887L19.3292 17.8745L13.2625 11.8078L11.8483 13.222Z" fill="black" />
          <path d="M13.2625 24.1922C12.872 24.5827 12.2388 24.5827 11.8483 24.1922C11.4578 23.8017 11.4578 23.1685 11.8483 22.778L13.2625 24.1922ZM11.8483 22.778L17.915 16.7113L19.3292 18.1255L13.2625 24.1922L11.8483 22.778Z" fill="black" />
          <line x1="23.4446" y1="12.5" x2="23.4446" y2="23.5" stroke="black" strokeWidth="2" strokeLinecap="round" />
        </svg>

      </div>
    </div>
  )
}

export default OrderTablePagination
