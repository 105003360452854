import React from 'react'
import Tooltip from '@material-ui/core/Tooltip';

const ChangeState = (props) => {
  let selfClass;
  let itemContent;
  let tooltipLabel = "";
  let nextStatus = "";
  if (props.type)
    switch (props.type) {
      case "edit":
        selfClass = "changeState changeState--blue";
        nextStatus = "edit";
        itemContent = <>
          <span>Редактировать</span>
          <svg width="12" height="12" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip3123)">
              <path d="M6.81374 2.18562L9.81421 5.18609L3.29882 11.7015L0.623663 11.9968C0.265538 12.0364 -0.0370404 11.7336 0.00280337 11.3755L0.30046 8.69844L6.81374 2.18562ZM11.67 1.73891L10.2612 0.330078C9.82171 -0.109375 9.10897 -0.109375 8.66952 0.330078L7.34413 1.65547L10.3446 4.65594L11.67 3.33055C12.1094 2.89086 12.1094 2.17836 11.67 1.73891Z" />
            </g>
            <defs>
              <clipPath id="clip3123">
                <rect width="12" height="12" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </>
        break;
      case "copy":
        selfClass = "changeState changeState--green";
        nextStatus = "copy";
        itemContent = <><span>Копия</span>
          <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.625 6.59375H8.40625V2.375C8.40625 1.85732 7.98643 1.4375 7.46875 1.4375H6.53125C6.01357 1.4375 5.59375 1.85732 5.59375 2.375V6.59375H1.375C0.857324 6.59375 0.4375 7.01357 0.4375 7.53125V8.46875C0.4375 8.98643 0.857324 9.40625 1.375 9.40625H5.59375V13.625C5.59375 14.1427 6.01357 14.5625 6.53125 14.5625H7.46875C7.98643 14.5625 8.40625 14.1427 8.40625 13.625V9.40625H12.625C13.1427 9.40625 13.5625 8.98643 13.5625 8.46875V7.53125C13.5625 7.01357 13.1427 6.59375 12.625 6.59375Z" />
          </svg></>;
        break;
      case "view":
        selfClass = "changeState changeState--blue";
        nextStatus = "view";
        itemContent = <>
          <span>Чтение</span>
          <svg width="16" height="14" viewBox="0 0 16 14" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip122DD0)">
              <path d="M7.87499 3.9375C7.58518 3.94203 7.29751 3.98807 7.02077 4.07422C7.14878 4.29934 7.21694 4.55353 7.21874 4.8125C7.21874 5.01359 7.17913 5.2127 7.10218 5.39848C7.02522 5.58426 6.91243 5.75307 6.77024 5.89526C6.62805 6.03745 6.45925 6.15024 6.27347 6.22719C6.08769 6.30414 5.88857 6.34375 5.68749 6.34375C5.42852 6.34195 5.17433 6.27379 4.9492 6.14578C4.77159 6.76178 4.79229 7.41805 5.00838 8.02163C5.22446 8.62521 5.62498 9.1455 6.1532 9.5088C6.68141 9.87211 7.31055 10.06 7.95148 10.0459C8.59242 10.0318 9.21267 9.81634 9.72437 9.43012C10.2361 9.04391 10.6133 8.50648 10.8026 7.89397C10.9919 7.28146 10.9837 6.62492 10.7791 6.01734C10.5745 5.40976 10.184 4.88195 9.66274 4.50867C9.14153 4.13539 8.51608 3.93557 7.87499 3.9375V3.9375ZM15.6548 6.60078C14.172 3.70754 11.2364 1.75 7.87499 1.75C4.51362 1.75 1.57717 3.70891 0.0951413 6.60105C0.0325903 6.72479 0 6.86149 0 7.00014C0 7.13878 0.0325903 7.27549 0.0951413 7.39922C1.57799 10.2925 4.51362 12.25 7.87499 12.25C11.2364 12.25 14.1728 10.2911 15.6548 7.39895C15.7174 7.27521 15.75 7.13851 15.75 6.99986C15.75 6.86122 15.7174 6.72451 15.6548 6.60078V6.60078ZM7.87499 10.9375C5.17752 10.9375 2.70456 9.43359 1.36909 7C2.70456 4.56641 5.17725 3.0625 7.87499 3.0625C10.5727 3.0625 13.0454 4.56641 14.3809 7C13.0457 9.43359 10.5727 10.9375 7.87499 10.9375Z" />
            </g>
            <defs>
              <clipPath id="clip122DD0">
                <rect width="15.75" height="14" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </>
        break;
      case "back":
        selfClass = "changeState changeState--green";
        nextStatus = "view";
        itemContent = <><span>Назад</span>
          <svg width="17" height="19" viewBox="0 0 17 19" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip20sssd)">
              <path d="M9.59421 14.6161L4.40943 9.41296C4.24522 9.24773 4.24522 8.98054 4.40943 8.81531L9.59421 3.61218C9.75842 3.44695 10.0239 3.44695 10.1882 3.61218L10.8729 4.30125C11.0406 4.47 11.0371 4.7407 10.8659 4.90242L7.47348 8.20007H16.166C16.3966 8.20007 16.5853 8.38992 16.5853 8.62195V9.60632C16.5853 9.83835 16.3966 10.0282 16.166 10.0282H7.47348L10.8659 13.3223C11.0336 13.4876 11.0371 13.7583 10.8729 13.9235L10.1882 14.6126C10.0239 14.7813 9.75842 14.7813 9.59421 14.6161V14.6161ZM2.74988 15.4423V2.78601C2.74988 2.55398 2.56121 2.36414 2.33062 2.36414H1.35236C1.12177 2.36414 0.933105 2.55398 0.933105 2.78601V15.4423C0.933105 15.6743 1.12177 15.8641 1.35236 15.8641H2.33062C2.56121 15.8641 2.74988 15.6743 2.74988 15.4423Z" />
            </g>
            <defs>
              <clipPath id="clip20sssd">
                <rect x="0.933105" y="0.114136" width="15.6522" height="18" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </>;
        break;
      case "toList":
        selfClass = "changeState changeState--purple";
        nextStatus = "view";
        itemContent = <><span>К списку</span>
          <svg width="17" height="19" viewBox="0 0 17 19" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip20sssd)">
              <path d="M9.59421 14.6161L4.40943 9.41296C4.24522 9.24773 4.24522 8.98054 4.40943 8.81531L9.59421 3.61218C9.75842 3.44695 10.0239 3.44695 10.1882 3.61218L10.8729 4.30125C11.0406 4.47 11.0371 4.7407 10.8659 4.90242L7.47348 8.20007H16.166C16.3966 8.20007 16.5853 8.38992 16.5853 8.62195V9.60632C16.5853 9.83835 16.3966 10.0282 16.166 10.0282H7.47348L10.8659 13.3223C11.0336 13.4876 11.0371 13.7583 10.8729 13.9235L10.1882 14.6126C10.0239 14.7813 9.75842 14.7813 9.59421 14.6161V14.6161ZM2.74988 15.4423V2.78601C2.74988 2.55398 2.56121 2.36414 2.33062 2.36414H1.35236C1.12177 2.36414 0.933105 2.55398 0.933105 2.78601V15.4423C0.933105 15.6743 1.12177 15.8641 1.35236 15.8641H2.33062C2.56121 15.8641 2.74988 15.6743 2.74988 15.4423Z" />
            </g>
            <defs>
              <clipPath id="clip20sssd">
                <rect x="0.933105" y="0.114136" width="15.6522" height="18" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </>;
        break;
      case "none":
        selfClass = "none";
        itemContent = <></>
        break;
      default:
        itemContent = <>ОШИБКА</>
        break;
    }

  if (props.disabled)
    selfClass += " disabled";
  else selfClass += " changeState--hover";

  if (props.tooltip)
    tooltipLabel = props.tooltip;

  const handleClick = () => {
    if (props.onClick) {
      props.onClick();
    }
    if (props.setStatusItem) {
      props.setStatusItem(nextStatus);
    }
  }


  return (
    <Tooltip title={tooltipLabel}>
      <div onClick={props.disabled ? () => { } : () => {
        handleClick();
      }} className={selfClass}>
        {itemContent}
      </div>
    </Tooltip>
  )
}

export default ChangeState
