import React, { useContext, useState, useEffect } from 'react'
import { orderContext } from '../OrderListStore';
import Loader from 'react-spinners/RingLoader';
import { Ctx } from '../../../Store';
import rAPService from '../../../services/requestsApiDatabase.service';
import orderListUnit from '../orderList.unit';
import convertDataService from '../../../services/conversionData.service';
import OrderTable from './OrderTable';
import OrderTablePagination from './OrderTablePagination';
import OrderMobileTable from './OrderMobileTable';

const OrderTableContainer = (props) => {
  const cDS = new convertDataService();
  const { store } = useContext(Ctx);
  const unit = new orderListUnit();
  const request = new rAPService({ url: store.apiUrl });
  const { listStore, setOrderTableDate, setCurrentPage } = useContext(orderContext);
  const [earlyTableData, setEarlyTableData] = useState(null);
  const tableData = listStore.orderListTableData;

  const filterItems = props.filterItems;
  const setOrderSortedByColumn = props.setOrderSortedByColumn; 

  let countAllPages;
  const activeFilter = listStore.activeFilter;

  const otherSizePlace = 350; //px
  const mobileRowCount = 20; //px
  const clientHeight = document.documentElement.clientHeight; //px
  const rowHeight = unit.getRowHeight(); //px

  const [rowsCount, setRowsCount] = useState(unit.getRowsCount(clientHeight, otherSizePlace, rowHeight, mobileRowCount));

  const currentPage = listStore.currentPage;
  const [loadMobileTableData, setLoadMobileTableData] = useState('iCanStartDownloadData');


  const scrollListener = () => {
    if (loadMobileTableData === 'iCanStartDownloadData' && unit.getBottomPositionY() < 200 && unit.getBottomPositionY()) {
      setCurrentPage(currentPage + 1);
      setLoadMobileTableData('mobileDataIsLoading');
    }
  }

  useEffect(() => {
    console.log(cDS.thisIsDesctopVersion());
    if (!cDS.thisIsDesctopVersion()) {
      window.addEventListener("scroll", scrollListener);
      return () => {
        window.removeEventListener("scroll", scrollListener);
      };
    }
  });

  const prep_fact_order = (fact_tx, fact_rx) => {
    if (fact_tx && fact_rx) {
      return `${fact_rx} / ${fact_tx}`
    } else {
      return "-"
    }
  }


  if (!tableData) {
    const requestData = request.getOrderList(currentPage, rowsCount, filterItems);
    requestData.then(el => {
      if (el) {
        const tData = {
          pagination: el?.pagination || {},
          rows: el?.rows ? el.rows.map((row) => ({
            fact_order: prep_fact_order(row?.fact_tx || row?.fact_rx),
            ...row
          })) : []
        }
        setOrderTableDate(tData)
      }
    }
    );
  } else {
    if (tableData.pagination) {
      countAllPages = Math.ceil(
        tableData.pagination.countRows / rowsCount);
    }
  }

  if (tableData && !earlyTableData) {
    setEarlyTableData(tableData);
  }

  const updateEarlyTableData = () => {
    setEarlyTableData(tableData);
  }


  if (tableData && earlyTableData && tableData.pagination && earlyTableData.pagination && tableData.pagination.page !== earlyTableData.pagination.page) {
    updateEarlyTableData();
  }

  const loadMobileDataIfNeed = () => {
    if (loadMobileTableData === 'mobileDataIsLoading') {
      const requestData = request.getOrderList(currentPage, rowsCount, filterItems);
      requestData.then(el => {
        if (tableData.rows) {
          const newData = {
            pagination: el.pagination,
            rows: [...tableData.rows, ...el.rows]
          }
          setLoadMobileTableData('iCanStartDownloadData');
          setOrderTableDate(newData);
        }
      });
    }
  }

  loadMobileDataIfNeed();

  console.log(tableData);

  if (!tableData && cDS.thisIsDesctopVersion() && listStore.firtsLoadTableData) {
    return <div className="orderList_loaderContainer">
      <Loader size={250} color={"blue"} loading={true} />;
     </div>
  } else if (!tableData && cDS.thisIsDesctopVersion() && !listStore.firtsLoadTableData) {
    let countRows = 30;
    if (earlyTableData && earlyTableData?.status === false) countRows = 30;
    else countRows = earlyTableData.pagination.countRows;
    return (
      <div className="disabled">
        <OrderTable updateEarlyTableData={updateEarlyTableData} orderSortedByColumn={filterItems.orderSortedByColumn} setOrderSortedByColumn={setOrderSortedByColumn} activeFilter={activeFilter} tableData={earlyTableData} clientHeight={clientHeight} />
        <OrderTablePagination rowsCount={rowsCount} setRowsCount={setRowsCount} className="desktopData" activeFilter={activeFilter} countAllPages={countAllPages} allRowsCount={countRows} />
      </div>
    );
  }

  if (cDS.thisIsDesctopVersion()) {
    let countRows = 30;
    if (tableData.status === false) countRows = 30;
    else countRows = tableData.pagination.countRows;
    return (
      <>
        <OrderTable updateEarlyTableData={updateEarlyTableData} orderSortedByColumn={filterItems.orderSortedByColumn} setOrderSortedByColumn={setOrderSortedByColumn} activeFilter={activeFilter} tableData={tableData} clientHeight={clientHeight} />
        <OrderTablePagination rowsCount={rowsCount} setRowsCount={setRowsCount} className="desktopData" activeFilter={activeFilter} countAllPages={countAllPages} allRowsCount={countRows} />
      </>
    )
  } else {
    return (
      <OrderMobileTable countAllPages={countAllPages} rowsCount={rowsCount} tableData={tableData} />
    )
  }
}

export default OrderTableContainer
