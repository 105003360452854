import React from 'react';

/*
	**STATES

	size = "text"
	weight = "text"
	margin = "text"
	color = main | second | first | second | thirth | fourth | fifth | sixth | dark | lightdark | gray | lightgray| ultralightgray | background| white
*/

const TitleIcon = props => {
	let titleIconClass = 'titleIcon_lable titleIcon_' + props.color;
	return (
		<div className={'titleIcon ' + props.className}>
			<img
				style={{ marginRight: props.margin }}
				className='titleIcon_icon'
				src={props.icon}
				alt={props.icon}
			></img>

			<label
				style={{
					fontWeight: props.weight,
					fontSize: props.size
				}}
				className={titleIconClass}
			>
				{props.text}
			</label>
		</div>
	);
};

export default TitleIcon;
