import React, { useContext, useState } from "react";
import { Ctx } from "../../Store";
import convertDataService from "../../services/conversionData.service";
import Input from "../../components/inputs/SmallInput/SmallInput";
import OrderListFilter from "./OrderListFilter";
import OrderTableContainer from "./OrderTable/OrderTableContainer";
import { orderContext } from "./OrderListStore";

const OrderList = () => {
  const { store } = useContext(Ctx);
  const cDS = new convertDataService();
  const { listStore, setActiveFilter, clearTableData } = useContext(orderContext);

  //фильтрация
  const [orderCity, setOrderCity] = useState('');
  const [orderNumber, setOrderNumber] = useState('');
  const [orderDeliveryDate, setOrderDeliveryDate] = useState(null)
  const [filterWait, setFilterWait] = useState(false);
  const [filterConfirmed, setFilterConfirmed] = useState(false);
  const [filterInWork, setFilterInWork] = useState(false);
  const [filterPaid, setFilterPaid] = useState(false);
  const [filterLoss, setFilterLoss] = useState(false);
  const [orderSortedByColumn, setOrderSortedByColumn] = useState({
    column: "delivery_time",
    type: "desc"
  });

  const activeFilter = listStore.activeFilter;

  const maskForOrderDate = (date) => {
    cDS.maskByDate(date, setOrderDeliveryDate, "DateYMD");
    clearTableData();
  }

  const handleChangeOrderSity = (value) => {
    setOrderCity(value);
  }

  const filterItems = {
    client_id: store.user.user_settings.client_id,
    orderCity,
    orderNumber,
    orderDeliveryDate,
    filterWait,
    filterConfirmed,
    filterInWork,
    filterPaid,
    filterLoss,
    orderSortedByColumn
  }

  const filterSetters = {
    setOrderCity,
    setOrderNumber,
    setOrderDeliveryDate,
    setFilterWait,
    setFilterConfirmed,
    setFilterInWork,
    setFilterPaid,
    setFilterLoss,
    maskForOrderDate,
    handleChangeOrderSity
  }

  //проверка на возможность изменения/отображения полей города и номера
  const selfNumClass = "closeFilter mainInput_small pageContent_title-input";
  const classOrderSelfNum = cDS.getClassOrderSelfNum(store, selfNumClass);
  const onInputPress = (e) => {
    if (e.key === "Enter") {
      clearTableData();
    }
  }

  document.onclick = function (e) {
    if (e.target.className.baseVal === "") {
      return null;
    }
    if (e.target.className.baseVal) {
      if (e.target.className.baseVal.indexOf("closeFilter") > -1) {
        setActiveFilter(false);
        return null;
      }
      return null;
    }
    if (e.target.className)
      if (e.target.className.indexOf("closeFilter") > -1) {
        setActiveFilter(false);
        return null;
      }
  };

  return (
    <section className="orderList closeFilter">
      <div className="pageContent closeFilter">
        <OrderListFilter filterItems={filterItems} filterSetters={filterSetters} />
        <div className="closeFilter pageContent_title">
          <div className="orderFilterClickItem pageContent_title-filter">
            <svg onClick={() => { setActiveFilter(!activeFilter) }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M464 32H48C21.5 32 0 53.5 0 80v64c0 8.8 7.2 16 16 16h16v272c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V160h16c8.8 0 16-7.2 16-16V80c0-26.5-21.5-48-48-48zm-16 400c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V160h384v272zm32-304H32V80c0-8.8 7.2-16 16-16h416c8.8 0 16 7.2 16 16v48zM204 256h104c6.6 0 12-5.4 12-12v-8c0-6.6-5.4-12-12-12H204c-6.6 0-12 5.4-12 12v8c0 6.6 5.4 12 12 12z" /></svg>
            <span className="orderFilterClickItem" onClick={() => { setActiveFilter(!activeFilter) }}>Фильтр</span>
          </div>
          <Input disabled={activeFilter} clear={true} value={orderNumber} changeValue={setOrderNumber} className={classOrderSelfNum} label="Номер" onKeyPress={onInputPress} onClearPress={() => { clearTableData() }} />
        </div>
        <OrderTableContainer setOrderSortedByColumn={setOrderSortedByColumn} filterItems={filterItems} filterSetters={filterSetters} />
      </div>
    </section>
  );
};

export default OrderList;
