import React, { useState } from 'react'
import convertDataService from '../../../services/conversionData.service';
import { Redirect } from 'react-router-dom';
import StatusViwer from '../../../components/StatusViwer/StatusViwer';


const OrderMobileItem = (props) => {
  const cDS = new convertDataService();
  const row = props.row;
  const [redirect, setRedirect] = useState();
  const [viewData, setViewData] = useState(false);
  const orderStatus = cDS.getStatusByOrderData(row);
  let classInvisibleRows = "orderMobileTable_item-line";
  let textLength = 30;

  const editOrder = () => {
    setRedirect(row.idorders);
  }

  const copyOrder = () => {
    setRedirect(row.idorders + "/copy");
  }

  const eyeAction = () => {
    setViewData(!viewData)
  }

  if (redirect) return <Redirect to={`/order-edit/${redirect}`} />

  if (viewData) {
    classInvisibleRows = "orderMobileTable_item-line";
    textLength = 80;
  } else {
    classInvisibleRows += " orderMobileTable_item-line--hidden";
  }

  const delivery_time = "c " + cDS.parseSepareteDate(row.delivery_time, ".", "DateDM") + " по " + cDS.parseSepareteDate(row.return_time, ".", "DateDM");

  let delivery_place = "Выдача: " + row.delivery_place.slice(0, textLength);
  if (row.delivery_place.length > textLength)
    delivery_place += "...";
  let number = "№" + row.mamanualnumber ? row.manualnumber : row.idorders;
  const amount = row.recivers + "(" + row.transmitters + ")";

  let return_place = "Возврат: " + row.return_place.slice(0, textLength);
  if (row.return_place.length > textLength) {
    return_place += "...";
  }

  const guide = "Гид: " + row.guide;

  const sum = "Сумма: " + row.sum + "р.";

  return (
    <div className="orderMobileTable_item" onClick={eyeAction}>
      <div className="orderMobileTable_item-line">
        <StatusViwer type={orderStatus.class} title={orderStatus.title} text={orderStatus.text} />
      </div>
      <div className="orderMobileTable_item-line">
        <span>{number}</span>
        <span>{delivery_time}</span>
        <span>{amount}</span>
      </div>
      <div className="orderMobileTable_item-line">
        <span>{delivery_place}</span>
      </div>
      <div className={classInvisibleRows}>
        <span>{return_place}</span>
      </div>
      <div className={classInvisibleRows}>
        <span>{guide}</span>
      </div>
      <div className={classInvisibleRows}>
        <span>{sum}</span>
      </div>
      <div className="orderMobileTable_item-buttons">
        <button className="orderMobileTable_item-buttonLeft">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M288 288a64 64 0 0 0 0-128c-1 0-1.88.24-2.85.29a47.5 47.5 0 0 1-60.86 60.86c0 1-.29 1.88-.29 2.85a64 64 0 0 0 64 64zm284.52-46.6C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 96a128 128 0 1 1-128 128A128.14 128.14 0 0 1 288 96zm0 320c-107.36 0-205.46-61.31-256-160a294.78 294.78 0 0 1 129.78-129.33C140.91 153.69 128 187.17 128 224a160 160 0 0 0 320 0c0-36.83-12.91-70.31-33.78-97.33A294.78 294.78 0 0 1 544 256c-50.53 98.69-148.64 160-256 160z" />
          </svg>
        </button>
        <button onClick={copyOrder} className="orderMobileTable_item-buttonCenter"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M433.941 65.941l-51.882-51.882A48 48 0 0 0 348.118 0H176c-26.51 0-48 21.49-48 48v48H48c-26.51 0-48 21.49-48 48v320c0 26.51 21.49 48 48 48h224c26.51 0 48-21.49 48-48v-48h80c26.51 0 48-21.49 48-48V99.882a48 48 0 0 0-14.059-33.941zM352 32.491a15.88 15.88 0 0 1 7.431 4.195l51.882 51.883A15.885 15.885 0 0 1 415.508 96H352V32.491zM288 464c0 8.822-7.178 16-16 16H48c-8.822 0-16-7.178-16-16V144c0-8.822 7.178-16 16-16h80v240c0 26.51 21.49 48 48 48h112v48zm128-96c0 8.822-7.178 16-16 16H176c-8.822 0-16-7.178-16-16V48c0-8.822 7.178-16 16-16h144v72c0 13.2 10.8 24 24 24h72v240z" /></svg></button>
        <button onClick={() => { editOrder() }} className="orderMobileTable_item-buttonRight"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M493.25 56.26l-37.51-37.51C443.25 6.25 426.87 0 410.49 0s-32.76 6.25-45.26 18.74L12.85 371.12.15 485.34C-1.45 499.72 9.88 512 23.95 512c.89 0 1.78-.05 2.69-.15l114.14-12.61 352.48-352.48c24.99-24.99 24.99-65.51-.01-90.5zM126.09 468.68l-93.03 10.31 10.36-93.17 263.89-263.89 82.77 82.77-263.99 263.98zm344.54-344.54l-57.93 57.93-82.77-82.77 57.93-57.93c6.04-6.04 14.08-9.37 22.63-9.37 8.55 0 16.58 3.33 22.63 9.37l37.51 37.51c12.47 12.48 12.47 32.78 0 45.26z" /></svg></button>
      </div>
    </div>
  )
}

export default OrderMobileItem
