import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Store } from './Store';
import { BrowserRouter as Router } from 'react-router-dom';
import './styles/font_awesome.css'

const root = document.getElementById('root');

const Application = () => (
	<Store>
		<Router>
			<App />
		</Router>
	</Store>
);

ReactDOM.render(<Application />, root);
