import React from 'react'
import Tooltip from '@material-ui/core/Tooltip';

/*
  **  
    type = awaiting | confirmed | inWork  | completed | paid | canceled | oldest | error
    title = props.title
    text = props.text
*/


const StatusViwer = (props) => {
  let classViewerCircle = "viwerCircle ";
  if (props.type)
    classViewerCircle += " viwerCircle--" + props.type;
  else classViewerCircle += " viwerCircle--canceled";

  return (
    <Tooltip title={props.text}>
      <div className="statusViwer">
        <div className={classViewerCircle}></div>
        <span>{props.title}</span>
      </div>
    </Tooltip>
  )
}

export default StatusViwer
