import React from 'react'
import { Checkbox, Tooltip } from '@material-ui/core'

const OrderFilterCheckBox = (props) => {
  const getTooltipTitle = () => {
    if (props.tooltip) {
      return props.tooltip
    }
    return "";
  }

  const tooltipTitle = getTooltipTitle();

  return (
    <div onClick={props.onClick}>
      <Tooltip title={tooltipTitle}>
        <span className="orderFilterList-item_description">{props.title}</span>
      </Tooltip>
      <Tooltip title={tooltipTitle}>
        <Checkbox
          checked={props.checked}
          color="primary"
          inputProps={{ 'aria-label': 'secondary checkbox' }}
        />
      </Tooltip>
    </ div>
  )
}

export default OrderFilterCheckBox
