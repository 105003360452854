import React from 'react'
import { ru } from "date-fns/locale";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import convertDataService from '../../services/conversionData.service';
import { ValidationText } from '../../services/checkValidation.servise';

/** 
 * Another props:
 * disabled
 * showTimeSelect
 * selfStyle
 * */


const MainDatePicker = (props) => {
  let selfClas = "mainDatePicker";
  const cDS = new convertDataService();

  registerLocale("ru", ru);

  if (props.className)
    selfClas += " " + props.className;

  const ignorKeys = ["selected", "minDate", "maxDate", "className"];

  const componentProps = cDS.getComponentProps(props, ignorKeys);

  if (props.selfStyle) {
    selfClas += " mainDatePicker--" + props.selfStyle;
  }

  const getFormat = (format) => {
    switch (format) {
      case "DMYHM":
        return "dd.MM.yyyy HH:mm"
      case "DMY":
        return "dd.MM.yyyy"
      default:
        return "dd.MM.yyyy HH:mm"
    }
  }

  const pickerFormat = getFormat(props.format);

  const renameTimeTitle = () => {
    setTimeout(() => {
      if (document.getElementsByClassName("react-datepicker-time__header")[0]) {
        document.getElementsByClassName("react-datepicker-time__header")[0].innerHTML = "Время";
      } else {
        renameTimeTitle();
      }
    }, 5);
  }

  const getDate = (date) => {
    if (date === null) return null
    switch (typeof (date)) {
      case "string":
        return new Date(date.replace(/\s/, 'T'))
      case "object":
        return new Date(date);
      default:
        return null;
    }
  }

  const selected = getDate(props.selected);
  const minDate = getDate(props.minDate);
  const maxDate = getDate(props.maxDate);

  return (
    <div className={selfClas}>
      <DatePicker
        showPopperArrow={false}
        locale={"ru"}
        className="mainDatePicker_container"
        timeFormat="p"
        dateFormat={pickerFormat}
        timeIntervals={30}
        onCalendarOpen={renameTimeTitle}
        {...componentProps}
        selected={selected}
        minDate={minDate}
        maxDate={maxDate}
        isClearable
      />
      <ValidationText viewValidation={props.viewValidation} validation={props.validation} value={selected} />
    </div>
  )
}

export default MainDatePicker
