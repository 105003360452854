import React, { useContext, useEffect, useState, useCallback } from "react";
import { Ctx } from "./Store";
import { Switch, Route } from "react-router-dom";
import SideBar from "./components/SideBar/SideBar";
import MainMenu from "./components/MainMenu/MainMenu";
import CreateOrder from "./pages/CreateOrder/CreateOrder";
import NotFound from "./pages/NotFound/NotFound";
import authenticationService from "./services/authentication.service";
import "./styles/index.sass";
import LogInImage from "./pages/MainPage/LogInImage/LogInImage";
import MobileMenuList from "./components/MainMenu/MobileMenu/MobileMenuList";
import EditOrder from "./pages/EditOrder/EditOrder";
import rAPService from "./services/requestsApiDatabase.service";
import OrderWasCreated from "./pages/OrderWasCreated/OrderWasCreated";
import OrderListWrapper from "./pages/OrderList/OrderListWrapper";

const App = () => {
  const { store, setUser, setAllowedCities, setAllCities, setTranslations, setBaseCode } = useContext(Ctx);
  const [activeMobileBurger, setActiveMobileBurger] = useState(false);

  const closeMobileMenuInTabletDesktop = () => {
    if (activeMobileBurger && document.documentElement.clientWidth > 700) {
      setActiveMobileBurger(false);
    }
  }

  closeMobileMenuInTabletDesktop();


  const authentication = useCallback((login = "", password = "", setstatusLoad = null) => {
    let baseCode = localStorage.getItem("baseCode");
    if (login && password) {
      baseCode = "Basic " + window.btoa(login + ":" + password);
    }
    const auth = new authenticationService({
      url: store.apiUrl
    });
    const answer = auth.loginByBaseCode(baseCode).then(el => {
      if (el.status) {
        if (el.data.allowedCities) {
          // const alertWas = localStorage.getItem("alertByNewApp");
          // if (!alertWas) {
          //   localStorage.setItem("alertByNewApp", "user saw alert");
          //   alert("мы обновили дизайн личного кабинета, если у вас возникнут вопросы мы готовы помочь по телефону 8(800)600-12-05");
          // }
          const request = new rAPService({ url: store.apiUrl });
          setAllowedCities(el.data.allowedCities.map(item => {
            item.value = item.id;
            item.label = item.name;
            return item;
          }));
          setTranslations(el.data.translations)
          console.log("DATA!!!");
          console.log(el.data.user);
          setUser(el.data.user);
          setBaseCode(baseCode);
          request.getAllCitiesList().then(el => setAllCities(el.data.cityList));
        }
      } else {
        alert("неверный логин или пароль");
        if (setstatusLoad) setstatusLoad(false);
        setUser({});
        auth.logout();
      }
    });
    return answer;
  }, [setAllCities, setAllowedCities, setBaseCode, setTranslations, setUser, store.apiUrl])

  useEffect(() => {
    if (
      Object.keys(store.user).length === 0 &&
      localStorage.getItem("baseCode")
    ) {
      authentication();
    }
  }, [authentication, store.user])


  if (!store.user.username || !localStorage.getItem("baseCode"))
    return (
      <div className="App containerApp">
        <SideBar authentication={authentication}></SideBar>
        <LogInImage />
      </div>
    );


  return (
    <div className="App containerApp">
      <SideBar></SideBar>
      <MainMenu
        activeBurger={activeMobileBurger}
        setActiveBurger={setActiveMobileBurger}
      ></MainMenu>
      <div className="containerContent">
        <MobileMenuList
          setActiveBurger={setActiveMobileBurger}
          activeMobileBurger={activeMobileBurger}
        />
        <Switch>
          <Route path="/" component={OrderListWrapper} exact />
          <Route path="/order-new" component={CreateOrder} exact />
          <Route path="/order-created/:id" component={OrderWasCreated} exact />
          <Route path="/order-list" component={OrderListWrapper} exact />
          <Route path="/order-edit/:id" component={EditOrder} exact />
          <Route path="/order-edit/:id/:type" component={EditOrder} exact />
          <Route path="/" component={NotFound} />
        </Switch>
      </div>
    </div>
  );
};

export default App;
