import React, { useContext } from 'react'
import { Ctx } from '../../Store';
import convertDataService from '../../services/conversionData.service';
import MainDatePicker from '../../components/MainDatePicker/MainDatePicker';
import SelectMain from '../../components/selects/SelectMain/SelectMain';
import OrderFilterCheckBox from './OrderFilterCheckBox';
import { orderContext } from './OrderListStore';
import orderListUnit from './orderList.unit';

const OrderListFilter = (props) => {
  const { store } = useContext(Ctx);
  const { listStore, clearTableData } = useContext(orderContext);
  const unit = new orderListUnit();

  const cDS = new convertDataService();
  //проверка на возможность изменения/отображения полей города и номера
  const classOrderCitySelection = cDS.getClassOrderCitySelection(store);
  const checkboxTextAwaiting = cDS.getStatusByNum(0);
  const checkboxTextConfirmed = cDS.getStatusByNum(1);
  const checkboxTextInWork = cDS.getStatusByNum(2);
  const checkboxTextInPaid = cDS.getStatusByNum(3);
  const checkboxTextInFaile = cDS.getStatusByNum(7);

  const activeFilter = listStore.activeFilter;
  const orderFilterListClass = unit.getOrderFilterListClass(activeFilter);

  const { setFilterConfirmed, setFilterInWork, setFilterLoss, setFilterPaid, setFilterWait, maskForOrderDate, handleChangeOrderSity } = props.filterSetters;

  const { filterConfirmed, filterInWork, filterLoss, filterPaid, filterWait, orderCity, orderDeliveryDate } = props.filterItems;


  return (
    <div className={orderFilterListClass}>
      <div className="orderFilterList-item">
        <MainDatePicker selfStyle="small" format={"DMY"} className="orderFilterClickItem mt2 mb2 orderList_picker" selected={orderDeliveryDate} placeholderText="Дата выдачи" onChange={maskForOrderDate} />
      </div>
      <div className="orderFilterList-item">
        <SelectMain isSearchable={false} view="small" placeholder="Город" className={classOrderCitySelection} items={store.allowedCities} value={orderCity} changeValue={handleChangeOrderSity} />
      </div>
      <div className="orderFilterList-item">
        <OrderFilterCheckBox checked={filterWait} onClick={() => {
          setFilterWait(!filterWait);
          clearTableData();
        }} title={checkboxTextAwaiting.title} tooltip={checkboxTextAwaiting.text} />
      </div>
      <div className="orderFilterList-item">
        <OrderFilterCheckBox checked={filterConfirmed} onClick={() => { setFilterConfirmed(!filterConfirmed); clearTableData(); }} title={checkboxTextConfirmed.title} tooltip={checkboxTextConfirmed.text} />
      </div>
      <div className="orderFilterList-item">
        <OrderFilterCheckBox checked={filterInWork} onClick={() => { setFilterInWork(!filterInWork); clearTableData(); }} title={checkboxTextInWork.title} tooltip={checkboxTextInWork.text} />
      </div>
      <div className="orderFilterList-item">
        <OrderFilterCheckBox checked={filterPaid} onClick={() => { setFilterPaid(!filterPaid); clearTableData(); }} title="Не оплачен" tooltip={checkboxTextInPaid.text} />
      </div>
      <div className="orderFilterList-item">
        <OrderFilterCheckBox checked={filterLoss} onClick={() => { setFilterLoss(!filterLoss); clearTableData(); }} title="Есть потери" tooltip={checkboxTextInFaile.text} />
      </div>
    </div>
  )
}

export default OrderListFilter
