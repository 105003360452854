import React, { useState } from 'react';
import { ValidationText } from '../../../services/checkValidation.servise';

/*
	**STATES

	placeholder = "text"
	label = "text"
	labelDisplay = true | false; default = true
	badValidatinText = text; automaticle ON validation
	type = text | password | email; default = text
	name = "text"
	value = "$variable"
	changeValue = "$setValue"; from parrent
	className = Class
  copy
  disabled
*/

const Input = props => {
	const [currentvalue, setCurrentvalue] = useState('');
	const handleInputChange = e => {
		if (props.changeValue)
			props.changeValue(e.target.value);
		else if (props.onChange)
			props.onChange(e.target.value);
		else
			setCurrentvalue(e.target.value);
	};
	let inputClass = 'mainInput ' + props.className;
	let inputType = 'text';
	let name = '';
	let labelText = '';
	let labelDisplay = 'mainInput_labelOn';
	let labelClass = '';
	let iconClass = 'mainInput_icon_off';

	if (props.type) inputType = props.type;
	labelClass += ' ' + labelDisplay;

	inputClass += ' ' + props.className;
	if (props.labelDisplay === false || props.placeholder)
		labelDisplay = 'mainInput_labelOff';
	if (props.label) labelText = props.label;

	//действия если поле не пусто
	if (props.value || currentvalue || props.value === 0) {
		labelClass += ' mainInput_notEmpty';
	}

	if (props.copy) iconClass = 'mainInput_icon_on';

	//если поле отключено
	if (props.disabled) {
		inputClass += ' disabled';
	}

	let action = <></>;

	let clearIconClass = "mainInput_clear"
	if (props.value)
		clearIconClass += ' mainInput_clear--enable'
	else
		clearIconClass += ' mainInput_clear--disabled'
	let clearIcon = <></>;
	if (props.clear && !props.copy) {
		clearIcon = <svg className={clearIconClass} onClick={() => {
			props.changeValue(null);
			if (props.onClearPress)
				props.onClearPress();
		}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z" /></svg>
	}

	return (
		<div className={inputClass}>
			<input
				value={props.value ? props.value : currentvalue}
				type={inputType}
				placeholder={props.placeholder}
				onChange={handleInputChange}
				name={name}
				readOnly={props.disabled}
				mykey={props.myKey}
				onKeyPress={props.onKeyPress ? props.onKeyPress : () => { }}
			/>
			<label htmlFor={name} className={labelClass}>
				{labelText}
			</label>
			{action}
			<svg
				width='16'
				height='18'
				viewBox='0 0 16 18'
				id='icon'
				xmlns='http://www.w3.org/2000/svg'
				className={iconClass}
			>
				<g clipPath='url(#clip0)'>
					<path d='M15.4979 2.31824L13.645 0.494262C13.3235 0.177794 12.8874 2.34027e-06 12.4328 0L6.28571 0C5.33893 0 4.57143 0.755508 4.57143 1.6875V3.375H1.71429C0.7675 3.375 0 4.13051 0 5.0625V16.3125C0 17.2445 0.7675 18 1.71429 18H9.71429C10.6611 18 11.4286 17.2445 11.4286 16.3125V14.625H14.2857C15.2325 14.625 16 13.8695 16 12.9375V3.51148C16 3.06392 15.8194 2.6347 15.4979 2.31824ZM12.5714 1.14226C12.6719 1.16667 12.7637 1.21767 12.8368 1.28974L14.6898 3.11375C14.763 3.18575 14.8148 3.2761 14.8396 3.375H12.5714V1.14226ZM10.2857 16.3125C10.2857 16.6226 10.0294 16.875 9.71429 16.875H1.71429C1.39921 16.875 1.14286 16.6226 1.14286 16.3125V5.0625C1.14286 4.75235 1.39921 4.5 1.71429 4.5H4.57143V12.9375C4.57143 13.8695 5.33893 14.625 6.28571 14.625H10.2857V16.3125ZM14.8571 12.9375C14.8571 13.2476 14.6008 13.5 14.2857 13.5H6.28571C5.97064 13.5 5.71429 13.2476 5.71429 12.9375V1.6875C5.71429 1.37735 5.97064 1.125 6.28571 1.125H11.4286V3.65625C11.4286 4.12031 11.8143 4.5 12.2857 4.5H14.8571V12.9375Z' />
				</g>
			</svg>
			{clearIcon}
			<ValidationText viewValidation={props.viewValidation} validation={props.validation} value={props.value} />
		</div>
	);
};

export default Input;
