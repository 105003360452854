import React from 'react';
import appleImg from './AppleStore.svg';
import googleImg from './google_play.png';
import { getClassByMod } from '../../services/generator';
import classNames from 'classnames';

const SideApps = ({ direction = 'column', className }) => {
	const modClass = getClassByMod(direction, 'column', 'sideApps');
	const sideAppsClass = classNames(modClass, 'sideApps', className);

	return (
		<div className={sideAppsClass}>
			<a href='https://apps.apple.com/ru/developer/vitaly-gontarik/id1502775701'>
				<img src={appleImg} alt='apple store' />
			</a>
			<a href='https://play.google.com/store/apps/developer?id=Crystal-Sound'>
				<img src={googleImg} alt='goole play' />
			</a>
		</div>
	);
};

export default SideApps;
