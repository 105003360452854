export default class orderListUnit {

  getBottomPositionY = () => {
    const scrollHeight = Math.max(
      document.body.scrollHeight, document.documentElement.scrollHeight,
      document.body.offsetHeight, document.documentElement.offsetHeight,
      document.body.clientHeight, document.documentElement.clientHeight
    );

    return scrollHeight - window.pageYOffset - document.documentElement.clientHeight;
  }

  getRowsCount = (clientHeight, otherSizePlace, rowHeight, mobileRowCount) => {
    const mobileWidth = 630; //px
    let baseCount = Math.floor((clientHeight - otherSizePlace) / rowHeight);
    if (document.documentElement.clientWidth < mobileWidth) {
      baseCount = mobileRowCount;
    }
    return baseCount
  }

  getRowHeight = () => {
    let baseHeight = 55; //px
    const tabletWidth = 1150; //px
    if (document.documentElement.clientWidth < tabletWidth)
      baseHeight = 65;
    return baseHeight;
  }

  getOrderFilterListClass = (state) => {
    let baseClass = "orderFilterClickItem orderFilterList";
    if (state) baseClass += " orderFilterList--active";
    return baseClass;
  }


}