import React, { useState, useCallback } from 'react'
import Input from '../../inputs/MainInput/MainInput'
import convertDataService from '../../../services/conversionData.service';


/*
  **KeyCodes
  27 = esc
  13 = enter
  40 = key down
  38 = key up  

*/

const CreatableSelect = (props) => {
  // console.log(props);


  const [itemsView, setItemsView] = useState("creatableSelect_itemsContainer--hidden");
  const cDS = new convertDataService();
  const [myKey] = useState(cDS.getRandomKey())
  const [indexOfSearchebleItem, setIndexOfSearchebleItem] = useState(0);



  const handleItemClick = (value) => {
    props.changeValue(value);
    setItemsView("creatableSelect_itemsContainer--hidden");
  }

  const getSearchebleArray = () => {
    if (!props.value)
      return props.items;
    return props.items.filter(el => el.value.toLowerCase().includes(props.value.toLowerCase()));
  }

  const searchebleArray = useCallback(getSearchebleArray(), [props.value, props.items]);

  const getArrayOfItems = () => (
    searchebleArray.map((el, idx) => {
      return <li
        id={idx === indexOfSearchebleItem
          ? myKey
          : null}
        onClick={() => { handleItemClick(el.value) }}
        key={cDS.getRandomKey()}
        className={
          idx === indexOfSearchebleItem
            ? "creatableSelect_itemsContainer_selected"
            : ""
        }
      >
        {el.value}
      </li>
    })
  )

  const closeItemsList = (e) => {
    if (e.target && e.target.blur) {
      e.target.blur();
    }
    setIndexOfSearchebleItem(0);
    setItemsView("creatableSelect_itemsContainer--hidden");
  }

  const arrayOfItems = useCallback(getArrayOfItems(), [searchebleArray, indexOfSearchebleItem]);

  if (props.disabled) {
    return <Input  {...props} />
  }

  const onFocus = () => {
    setItemsView("")
  }

  const onBlur = (e) => {
    e.persist(setTimeout(() => { closeItemsList(e) }, 200));
  }
  const onKeyDown = (e) => {
    switch (e.keyCode) {
      case 27:
        closeItemsList(e)
        break;
      case 13:
        if (arrayOfItems[indexOfSearchebleItem] && arrayOfItems[indexOfSearchebleItem].props) {
          props.changeValue(arrayOfItems[indexOfSearchebleItem].props.children);
          closeItemsList(e);
        }
        break;
      case 40:
        // console.log(40);
        if (indexOfSearchebleItem < arrayOfItems.length - 1) {
          setIndexOfSearchebleItem(indexOfSearchebleItem + 1);
          const elScroll = document.getElementById(myKey).offsetTop;
          document.getElementById(myKey).parentNode.parentNode.scrollTo(0, elScroll);
        }
        break;
      case 38:
        console.log(38);
        if (indexOfSearchebleItem > 0) {
          setIndexOfSearchebleItem(indexOfSearchebleItem - 1)
          const elScroll = document.getElementById(myKey).offsetTop - 100;
          document.getElementById(myKey).parentNode.parentNode.scrollTo(0, elScroll);
        }
        break;
      default:
        break;
    }
  }

  let hiddenWhenEmpty = "";
  if (searchebleArray.length === 0)
    hiddenWhenEmpty = "creatableSelect_itemsContainer--hidden";

  return (
    <div
      onFocus={onFocus}
      onBlur={onBlur}
      onKeyDown={onKeyDown}
      className="creatableSelect">
      <Input  {...props} />
      <div
        onFocus={() => { setItemsView("") }}
        className={`creatableSelect_itemsContainer ${itemsView} ${hiddenWhenEmpty}`}
      >
        <ul>
          {arrayOfItems}
        </ul>
      </div>
    </div>
  )
}

export default CreatableSelect
