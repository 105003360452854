import React from 'react'

const TextArea = (props) => {
  const handleChangeText = (e) => {
    props.changeText(e.target.value);
  }
  if (props.disabled)
    return (
      <textarea readOnly className="disabled" onChange={handleChangeText} placeholder="Комментарий..." name="" cols="30" rows="4" defaultValue={props.text}>
      </textarea>
    )

  return (
    <textarea onChange={handleChangeText} placeholder="Комментарий..." name="" cols="30" rows="4" defaultValue={props.text}>
    </textarea>
  )
}

export default TextArea
