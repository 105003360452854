import React from 'react'
import OrderList from './OrderList'
import { OrderListStore } from './OrderListStore'


const OrderListWrapper = () => {
  return (
    <OrderListStore>
      <OrderList />
    </OrderListStore>
  )
}

export default OrderListWrapper
