import classNames from 'classnames';

export const randKey = () =>
	Math.random()
		.toString(36)
		.replace(/[^a-z]+/g, '')
		.substr(0, 8);

export const getKeysByMatch = (match) => {
	if (typeof match?.params === 'object') return Object.keys(match?.params);
	else return null;
};

export const getClassByMod = (newMod, matchMod, prefix, ...args) => {
	if (newMod === matchMod) return classNames(...args, `${prefix}_${matchMod}`);
	return classNames(`${prefix}_${newMod}`, ...args);
};

export const getClassByExist = (
	checkValue,
	mainClass,
	alterClass,
	commonClass?
) => {
	if (checkValue) return classNames(mainClass, commonClass);
	return classNames(alterClass, commonClass);
};
