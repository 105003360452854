import React, { useCallback, useState } from 'react';
import Loader from 'react-spinners/RingLoader';
import buttonTextUnit from './buttonText.unit';

/*
	**STATES

	value = text
	type = primary | secondary | danger 
	status = enabled | disabled
	observe = {
					status: true | false,
					variable: var
				}
	onClick = function()
	
*/


const ButtonText = props => {
	const [errorAnimation, setErrorAnimation] = useState("");

	let buttonValue = props.value;
	const unit = new buttonTextUnit();

	const buttonClass = useCallback(
		unit.getButtonClass(props),
		[props],
	)

	const handleClick = () => {
		if (props.disabled && props.disabledClick) {
			props.disabledClick();
		} else if (props.errrorAnimation && props.onClick) {
			props.onClick();
			setErrorAnimation("errorAnimation");
			setTimeout(() => {
				setErrorAnimation("");
			}, 1000);
		}
		else if (props.onClick) {
			props.onClick();
		}

	}

	if (props.loading)
		buttonValue = (
			<Loader size={30} color={'white'} loading={true} />
		);

	return (
		<>
			<button
				onClick={(handleClick)}
				className={`${buttonClass} ${errorAnimation}`}
			>
				{buttonValue}
			</button>
		</>
	);
};

export default ButtonText;
