import React, { useState, useContext } from "react";
import StepWizard from "react-step-wizard";
import FirstStep from "./FirstStep/FirstStep.js";
import SecondStep from "./SecondStep/SecondStep.js";
import { Ctx } from "../../Store.js";
import convertDataService from "../../services/conversionData.service.js";
import rAPService from "../../services/requestsApiDatabase.service.js";
import { Redirect } from "react-router-dom";
import Loader from "react-spinners/RingLoader";
import checkValidationServise from "../../services/checkValidation.servise.js";


const CreateOrder = () => {
  const { store } = useContext(Ctx);
  const cDS = new convertDataService();
  const request = new rAPService({ url: store.apiUrl });
  const validService = new checkValidationServise();
  const [step, setStep] = useState(1);
  const [redirect, setRedirect] = useState();

  const currentDate = cDS.getCurrentDate();

  // Данные списка заказов
  const [orderSelfNum, setOrderSelfNum] = useState('');
  const [orderCity, setOrderCity] = useState('');
  const [init, setInit] = useState(false);
  const [orderDeliveryDate, setOrderDeliveryDate] = useState('');
  const [orderDeliveryPlace, setOrderDeliveryPlace] = useState('');
  const [orderReturnDate, setOrderReturnDate] = useState(null);
  const [orderReturnPlace, setOrderReturnPlace] = useState('');
  const [orderAmountReceivers, setOrderAmountReceivers] = useState(null)
  const [orderAmountTransmitters, setOrderAmountTransmitters] = useState("1");
  const [orderExtraAmountReceivers, setOrderExtraAmountReceivers] = useState(0);
  const [orderGuidesArray, setOrderGuidesArray] = useState([]);
  const [orderMenegersArray, setOrderMenegersArray] = useState([]);
  const [orderComment, setOrderComment] = useState('');

  //отображение валидации для первого и второго шага
  const [viewFirstStepValidation, setViewFirstStepValidation] = useState(false);
  const [viewSecondStepValidation, setViewSecondStepValidation] = useState(false);



  //список мест возврата и доставок
  const [placesDaR, setPlacesDaR] = useState(null);

  const loader = (
    <section className="createOrder">
      <div>
        <div className="createOrder_form createOrder_center">
          <Loader size={300} color={"blue"} loading={true} />
        </div>
      </div>
    </section>
  )
  const checkValidSFirstStep = () => {
    if (
      validService.checkValidCity(orderCity).validated &&
      validService.checkValidPlace(orderDeliveryPlace).validated &&
      validService.checkValidPlace(orderReturnPlace).validated &&
      validService.checkValidNumByDevise(orderAmountReceivers).validated &&
      validService.checkValidNumByDevise(orderAmountTransmitters).validated &&
      validService.checkValidNumByDevise(orderExtraAmountReceivers).validated &&
      validService.checkValidDate(orderDeliveryDate).validated &&
      validService.checkValidDate(orderReturnDate).validated
    )
      return true
    else
      return false
  }


  const checkValidSecondStep = () => {
    if (validService.checkValidMember(orderGuidesArray).validated &&
      validService.checkValidMember(orderMenegersArray).validated)
      return true
    else
      return false
  }


  if (!placesDaR) {
    request.getPlacesDaR(store.user.user_settings.client_id).then(el => setPlacesDaR(el));
    return loader;
  }


  if (redirect)
    return <Redirect to={redirect} />

  //следующий шаг визарда
  const onStepChange = e => {
    setStep(e.activeStep);
  };


  //Отправка данных на сервер
  const pushOrder = () => {
    const employee_id = orderGuidesArray.map(el => el.id);
    const manager_id = orderMenegersArray.map(el => el.id);

    if (checkValidSFirstStep() && checkValidSecondStep()) {
      const order = {
        order: {
          client_id: store.user.user_settings.client_id,
          delivery_time: orderDeliveryDate + ":00",
          delivery_place: orderDeliveryPlace,
          return_time: orderReturnDate + ":00",
          return_place: orderReturnPlace,
          recivers: orderAmountReceivers,
          transmitters: orderAmountTransmitters,
          extra_recivers: orderExtraAmountReceivers,
          employee_id: employee_id,
          manager_id: manager_id,
          extra_service: orderComment,
          city: orderCity.id
        }
      };
      if (store.user.user_settings.can_pick_city)
        order.order.city = orderCity.id;
      if (store.user.user_settings.manual_doc_num)
        order.order.manualnumber = orderSelfNum;
      let newRequest = request.addOrder(order, 'post');
      newRequest.then(el => {
        if (el.ok) {
          el.json().then(answer => {
            const id = answer.data.order.idorders;
            setRedirect("/order-created/" + id);
          });
        } else {
          alert("Во время отправки данных произошла ошибка");
        }
      })
    } else {
      setViewSecondStepValidation(true);
    }
  }

  //дата возврата должна быть больше даты возварата
  if (new Date(orderDeliveryDate) > new Date(orderReturnDate)) {
    setOrderReturnDate(cDS.parseSepareteDate(new Date(orderDeliveryDate), '-', 'DateYMDHM'));
  }


  //Функции связанные с инпутами данных заказа
  const maskForOrderDeliveryDate = (date) => {
    cDS.maskByDate(date, setOrderDeliveryDate, "DateYMDHM", true);
  }
  const maskForOrderReturnDate = (date) => {
    cDS.maskByDate(date, setOrderReturnDate);
  }
  const maskForOrderAmountReceivers = value => {
    setOrderAmountReceivers(cDS.wrapNumberMask(value));
  }
  const maskForOrderAmountTransmitters = value => {
    setOrderAmountTransmitters(cDS.wrapNumberMask(value));
  }
  const maskForOrderExtraAmountReceivers = value => {
    setOrderExtraAmountReceivers(cDS.wrapNumberMask(value))
  }
  const handleSetOrderSity = (id) => {
    let answer = null;
    store.allowedCities.forEach((el) => {
      if (id === el.id) {
        answer = el;
      }
    });
    return answer;
  }
  //изменение количества приемников
  if (orderAmountTransmitters && store.user.role > 1 && parseInt(orderAmountTransmitters) !== parseInt(orderExtraAmountReceivers))
    setOrderExtraAmountReceivers(orderAmountTransmitters);
  let isDisabledOrderExtraAmountReceivers = false;
  //блокирования поля приемников
  if (store.user.role > 1)
    isDisabledOrderExtraAmountReceivers = true;

  //функции и данные для первого шага
  const firstStepValues = {
    orderSelfNum,
    orderCity,
    orderDeliveryDate,
    orderDeliveryPlace,
    orderReturnDate,
    orderReturnPlace,
    isDisabledOrderExtraAmountReceivers,
    orderAmountReceivers,
    orderAmountTransmitters,
    orderExtraAmountReceivers,
    placesDaR,
    viewFirstStepValidation
  }
  const firstStepFunctions = {
    maskForOrderDeliveryDate,
    maskForOrderReturnDate,
    maskForOrderAmountReceivers,
    handleSetOrderSity,
    maskForOrderAmountTransmitters,
    maskForOrderExtraAmountReceivers,
    setOrderCity,
    setOrderSelfNum,
    setOrderDeliveryPlace,
    setOrderReturnPlace,
    checkValidSFirstStep,
    setViewFirstStepValidation
  }

  //функции и данные для второго шага
  const secondStepValues = {
    orderGuidesArray,
    orderMenegersArray,
    orderComment,
    viewSecondStepValidation
  }
  const secondStepFunctions = {
    setOrderGuidesArray,
    setOrderMenegersArray,
    setOrderComment,
    pushOrder,
    checkValidSecondStep
  }

  //при первом запуске
  if (!init) {
    setOrderDeliveryDate(cDS.parseSepareteDate(cDS.getDateNextDay(1), '-', 'DateYMDHM'));
    setOrderReturnDate(null);
    setInit(true);
    const cityId = store.user.user_settings.city;
    if (cityId && cityId > 0) {
      if (cDS.getArrayOfKeysByArrayOfObjects(store.allowedCities, "id").includes(String(cityId))) {
        const item = cDS.getObjectByKeyFromArraysOfObjects(store.allowedCities, "id", cityId);
        if (item.status === "ok") setOrderCity(item.data);
      }
    }
  }


  return (
    <section className="createOrder">
      <div>
        <div className="createOrder_title">
          <div className="createOrder_title-left">
            <h2>Создание заказа</h2>
            <div className="createOrder_title--step">
              <i className="far fa-walking"></i>
              <span>{"Шаг: " + step + "/2"}</span>
            </div>
            <span className="none createOrder_title--description desktopData">
              атоматическое сохранение ✓
            </span>
          </div>
          <div className="createOrder_title-right">
            <span className="createOrder_title-date desktopData">
              {currentDate}
            </span>
            <span className="none createOrder_title--description none">
              атоматическое сохранение ✓
            </span>
          </div>
        </div>
        <div className="createOrder_form">
          <StepWizard onStepChange={onStepChange} nav={<></>}>
            <FirstStep firstStepFunctions={firstStepFunctions} firstStepValues={firstStepValues} />
            <SecondStep secondStepValues={secondStepValues} secondStepFunctions={secondStepFunctions} />
          </StepWizard>
        </div>
      </div>
    </section>
  );
};

export default CreateOrder;
