import React, { useContext } from "react";
import ButtonText from "../../../components/buttons/ButtonText/ButtonText";
import Input from "../../../components/inputs/MainInput/MainInput";
import SelectMain from "../../../components/selects/SelectMain/SelectMain";
import { Ctx } from "../../../Store";
import MainDatePicker from "../../../components/MainDatePicker/MainDatePicker";
import convertDataService from "../../../services/conversionData.service";
import checkValidationServise from "../../../services/checkValidation.servise";
import CreatableSelect from "../../../components/selects/CreatableSelect/CreatableSelect";

const FirstStep = props => {
  const { store } = useContext(Ctx);
  const cDS = new convertDataService();
  const validService = new checkValidationServise();

  //данные инпутов
  const orderSelfNum = props.firstStepValues.orderSelfNum;
  const orderCity = props.firstStepValues.orderCity;
  const orderDeliveryDate = props.firstStepValues.orderDeliveryDate;
  const orderDeliveryPlace = props.firstStepValues.orderDeliveryPlace;
  const orderReturnDate = props.firstStepValues.orderReturnDate;
  const orderReturnPlace = props.firstStepValues.orderReturnPlace;
  const isDisabledOrderExtraAmountReceivers = props.firstStepValues.isDisabledOrderExtraAmountReceivers;
  const orderAmountReceivers = props.firstStepValues.orderAmountReceivers;
  const orderAmountTransmitters = props.firstStepValues.orderAmountTransmitters;
  const orderExtraAmountReceivers = props.firstStepValues.orderExtraAmountReceivers;

  //список мест
  const placesDaR = props.firstStepValues.placesDaR;


  //функции изменения инпутов
  const maskForOrderDeliveryDate = props.firstStepFunctions.maskForOrderDeliveryDate;
  const maskForOrderReturnDate = props.firstStepFunctions.maskForOrderReturnDate;
  const maskForOrderAmountReceivers = props.firstStepFunctions.maskForOrderAmountReceivers;
  const maskForOrderAmountTransmitters = props.firstStepFunctions.maskForOrderAmountTransmitters;
  const maskForOrderExtraAmountReceivers = props.firstStepFunctions.maskForOrderExtraAmountReceivers;
  const setOrderCity = props.firstStepFunctions.setOrderCity;
  const setOrderSelfNum = props.firstStepFunctions.setOrderSelfNum;
  const setOrderDeliveryPlace = props.firstStepFunctions.setOrderDeliveryPlace;
  const setOrderReturnPlace = props.firstStepFunctions.setOrderReturnPlace;

  //Возможность следующего шага
  const checkValidSFirstStep = props.firstStepFunctions.checkValidSFirstStep;

  //Отображение валидации
  const viewFirstStepValidation = props.firstStepValues.viewFirstStepValidation;
  const setViewFirstStepValidation = props.firstStepFunctions.setViewFirstStepValidation;

  //проверка на возможность изменения/отображения полей города и номера
  const classOrderSelfNum = cDS.getClassOrderSelfNum(store);
  const classOrderItem = cDS.getClassOrderItem(store);

  let buttonErrorAnimation = false;
  if (!checkValidSFirstStep()) {
    buttonErrorAnimation = true;
  }

  const handleNextStep = () => {
    if (checkValidSFirstStep()) props.nextStep()
    else if (!viewFirstStepValidation) setViewFirstStepValidation(true)
  }


  return (
    <div>
      <div className={classOrderItem}>
        <div className="createOrder_form-item--title">
          <i className="fal fa-box-full red"></i>
          <h3>Заказ</h3>
        </div>
        <div className="createOrder_form-item--content">
          <div className="createOrder_form-item--content_partFirst">
            <Input
              className={classOrderSelfNum} value={orderSelfNum} changeValue={setOrderSelfNum} label="Свой номер" copy />
          </div>
          <div className="createOrder_form-item--content_partSecond">
            <SelectMain
              isSearchable={false}
              placeholder="Выбрать город"
              items={store.allowedCities}
              value={orderCity}
              changeValue={setOrderCity}
              validation={validService.checkValidCity}
              viewValidation={viewFirstStepValidation}
            />
          </div>
        </div>
      </div>
      <div className="createOrder_form-item">
        <div className="createOrder_form-item--title">
          <i className="fal fa-truck blue"></i>
          <h3>Доставка</h3>
        </div>
        <div className="createOrder_form-item--content">
          <div className="createOrder_form-item--content_partFirst">
            <MainDatePicker
              showTimeSelect
              minDate={new Date()}
              selected={orderDeliveryDate}
              placeholderText="Дата доставки"
              onChange={maskForOrderDeliveryDate}
              isClearable
              validation={validService.checkValidDate}
              viewValidation={viewFirstStepValidation}
            />
          </div>
          <div className="createOrder_form-item--content_partSecond">
            <CreatableSelect
              isSearchable={true}
              value={orderDeliveryPlace}
              changeValue={setOrderDeliveryPlace}
              items={placesDaR.placesDelivery}
              isCreatable={true}
              validation={validService.checkValidPlace}
              viewValidation={viewFirstStepValidation}
              personalValue={true}
              label="Место доставки"
            />
          </div>
        </div>
      </div>
      <div className="createOrder_form-item">
        <div className="createOrder_form-item--title">
          <i className="fal fa-parachute-box purple"></i>
          <h3>Возврат</h3>
        </div>
        <div className="createOrder_form-item--content">
          <div className="createOrder_form-item--content_partFirst">
            <MainDatePicker
              showTimeSelect
              selected={orderReturnDate}
              placeholderText="Дата возврата"
              minDate={orderDeliveryDate}
              onChange={maskForOrderReturnDate}
              validation={validService.checkValidDate}
              viewValidation={viewFirstStepValidation}
            />
          </div>
          <div className="createOrder_form-item--content_partSecond">
            <CreatableSelect
              label="Место возврата"
              isSearchable={true}
              items={placesDaR.placesReturn}
              value={orderReturnPlace}
              changeValue={setOrderReturnPlace}
              isCreatable={true}
              validation={validService.checkValidPlace}
              viewValidation={viewFirstStepValidation}
              personalValue={true}
            />

          </div>
        </div>
      </div>
      <div className="createOrder_form-amount">
        <div className="createOrder_form-amount-partFirst">
          <div className="createOrder_form-item--title">
            <i className="fal fa-boxes-alt yellow"></i>
            <h3>Количество</h3>
          </div>
          <div className="createOrder_form-amount-partFirst_content">
            <Input
              className="createOrder_input createOrder_leftInput"
              label="Приемников" value={orderAmountReceivers} changeValue={maskForOrderAmountReceivers}
              validation={validService.checkValidNumByDevise}
              viewValidation={viewFirstStepValidation}
            />
            <Input className="createOrder_input" value={orderAmountTransmitters} changeValue={maskForOrderAmountTransmitters} label="Передатчиков" validation={validService.checkValidNumByDevise}
              viewValidation={viewFirstStepValidation}
            />
          </div>
        </div>
        <div className="createOrder_form-amount-PartSecond">
          <div className="createOrder_form-item--title">
            <i className="fal fa-puzzle-piece yellow"></i>
            <h3>Дополнительно</h3>
          </div>
          <div className="createOrder_form-amount-PartSecond_content">
            <Input
              value={orderExtraAmountReceivers}
              changeValue={maskForOrderExtraAmountReceivers}
              className="createOrder_input"
              label="Приемников" disabled={isDisabledOrderExtraAmountReceivers}
            />
          </div>
        </div>
      </div>
      <div className="createOrder_button">
        <ButtonText
          type="primary"
          value="Далее"
          errrorAnimation={buttonErrorAnimation}
          onClick={() => { handleNextStep() }} />
      </div>
    </div>
  );
};

export default FirstStep;

