import React from "react";

const ButtonIconUser = (props) => {
  let buttonClass = "buttonIconUser";
  if (props.disabled)
    buttonClass += " disabled"
  else
    buttonClass += " buttonIconUser--active"
  return (
    <button onClick={() => {
      if (!props.disabled)
        props.action();
    }} className={buttonClass}>
      <svg
        width="26"
        height="21"
        viewBox="0 0 26 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g>
          <path
            d="M25.5376 8.82996H22.1001V5.39246C22.1001 5.22058 21.9595 5.07996 21.7876 5.07996H21.1626C20.9907 5.07996 20.8501 5.22058 20.8501 5.39246V8.82996H17.4126C17.2407 8.82996 17.1001 8.97058 17.1001 9.14246V9.76746C17.1001 9.93933 17.2407 10.08 17.4126 10.08H20.8501V13.5175C20.8501 13.6893 20.9907 13.83 21.1626 13.83H21.7876C21.9595 13.83 22.1001 13.6893 22.1001 13.5175V10.08H25.5376C25.7095 10.08 25.8501 9.93933 25.8501 9.76746V9.14246C25.8501 8.97058 25.7095 8.82996 25.5376 8.82996ZM13.1001 11.33C11.979 11.33 11.4399 11.955 9.6001 11.955C7.76025 11.955 7.2251 11.33 6.1001 11.33C3.20166 11.33 0.850098 13.6815 0.850098 16.58V18.205C0.850098 19.2401 1.68994 20.08 2.7251 20.08H16.4751C17.5103 20.08 18.3501 19.2401 18.3501 18.205V16.58C18.3501 13.6815 15.9985 11.33 13.1001 11.33ZM17.1001 18.205C17.1001 18.5487 16.8188 18.83 16.4751 18.83H2.7251C2.38135 18.83 2.1001 18.5487 2.1001 18.205V16.58C2.1001 14.3729 3.89307 12.58 6.1001 12.58C6.86572 12.58 7.62744 13.205 9.6001 13.205C11.5688 13.205 12.3345 12.58 13.1001 12.58C15.3071 12.58 17.1001 14.3729 17.1001 16.58V18.205ZM9.6001 10.08C12.3618 10.08 14.6001 7.84167 14.6001 5.07996C14.6001 2.31824 12.3618 0.0799561 9.6001 0.0799561C6.83838 0.0799561 4.6001 2.31824 4.6001 5.07996C4.6001 7.84167 6.83838 10.08 9.6001 10.08ZM9.6001 1.32996C11.6665 1.32996 13.3501 3.01355 13.3501 5.07996C13.3501 7.14636 11.6665 8.82996 9.6001 8.82996C7.53369 8.82996 5.8501 7.14636 5.8501 5.07996C5.8501 3.01355 7.53369 1.32996 9.6001 1.32996Z"
            fill="white"
          />
        </g>
        <defs>
        </defs>
      </svg>
    </button>
  );
};

export default ButtonIconUser;
