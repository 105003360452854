import React, { createContext, useReducer } from "react";

const initialState = {
  apiUrl: null,
  roles: ["admin", "moderator", "admin"],
  user: {},
  allowedCities: {},
  allCities: {},
  dataNewOrder: {
    mainInfo: {
      id: 0,
      selfId: "",
      city: "Санкт-Петербург"
    },
    delivery: {
      date: "",
      time: "",
      place: ""
    },
    return: {
      date: "",
      time: "",
      place: ""
    },
    amountMain: {
      receivers: "",
      transmitters: ""
    },
    amountExtra: {
      receivers: "",
      transmitters: ""
    },
    guides: [],
    menegers: [],
    comment: ""
  },
  menuFields: {
    home: "Домой",
    newOrder: "Новый заказ",
    orderList: "Список",
    calendar: "Календарь",
    help: "Помощь",
    exit: "Выход"
  },
  translations: {
  },
  baseCose: null
};


const Ctx = createContext({
  store: initialState
});

const reduser = (store, action) => {
  switch (action.type) {
    case "setUser":
      return { ...store, user: action.payload };
    case "setAllowedCities":
      return { ...store, allowedCities: action.payload }
    case "setAllCities":
      return { ...store, allCities: action.payload }
    case "setTranslations":
      return { ...store, translations: action.payload }
    case "setApiUrl":
      let newApi = "https://test.crystal-sound.com";
      const host = window.location.host;
      if (host === "my.crystal-sound.com") {
        newApi = "https://client.crystal-sound.com";
        return { ...store, apiUrl: newApi }
      }
      return { ...store, apiUrl: newApi }
    case "setBaseCode":
      return { ...store, baseCode: action.payload }
    default:
      return store;
  }
};

const Store = props => {
  const [store, dispatch] = useReducer(reduser, initialState);
  const events = {
    "setUser": user =>
      dispatch({
        type: "setUser",
        payload: user
      }),
    setAllCities: cities => dispatch({ type: "setAllCities", payload: cities }),
    setAllowedCities: cities => dispatch({ type: "setAllowedCities", payload: cities }),
    setTranslations: translations => dispatch({ type: "setTranslations", payload: translations }),
    setApiUrl: () => dispatch({ type: "setApiUrl", payload: null }),
    setBaseCode: (baseCode) => dispatch({ type: "setBaseCode", payload: baseCode })
  };

  if (store.apiUrl === null) {
    events.setApiUrl();
    return null;
  }

  return (
    <Ctx.Provider value={{ ...events, store }}>{props.children}</Ctx.Provider>
  );
};

export { Store, Ctx };
