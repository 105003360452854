import { type, zipObj, without } from 'ramda';

export default class convertDataService {
	getRandomKey = () => {
		return Math.random().toString(36).slice(3);
	};

	getDateByType = (date) => {
		if (type(date) === 'String') return new Date(date.replace(/\s/, 'T'));
		return new Date(date);
	};

	parseSepareteDate = (date = new Date(), symbol = '.', mode = 'DateDMY') => {
		const selfDate = this.getDateByType(date);
		let dateBits = {
			year: selfDate.getFullYear(),
			month: selfDate.getMonth() + 1,
			day: selfDate.getDate(),
			hours: selfDate.getHours(),
			minutes: selfDate.getMinutes(),
		};
		Object.keys(dateBits).forEach((key) => {
			if (dateBits[key] < 10) dateBits[key] = '0' + dateBits[key];
		});
		switch (mode) {
			case 'DateDMY':
				return dateBits.day + symbol + dateBits.month + symbol + dateBits.year;
			case 'DateDM':
				return dateBits.day + symbol + dateBits.month;
			case 'DateYMD':
				return dateBits.year + symbol + dateBits.month + symbol + dateBits.day;
			case 'DateDMYHM':
				return (
					dateBits.day +
					symbol +
					dateBits.month +
					symbol +
					dateBits.year +
					' ' +
					dateBits.hours +
					':' +
					dateBits.minutes
				);
			case 'DateYMDHM':
				return (
					dateBits.year +
					symbol +
					dateBits.month +
					symbol +
					dateBits.day +
					' ' +
					dateBits.hours +
					':' +
					dateBits.minutes
				);
			case 'DateHM':
				return dateBits.hours + ':' + dateBits.minutes;
			default:
				return dateBits.day + symbol + dateBits.month + symbol + dateBits.year;
		}
	};

	parseDatetoServer = (date) => {
		let day = date.slice(0, 2);
		let month = date.slice(3, 5);
		let year = date.slice(6, 10);
		return year + '-' + month + '-' + day;
	};

	getOrderTableFields = () => {
		return {
			idorders: '№ и дата док-та',
			// manualnumber: `Свой \n номер`,
			client_name: 'Клиент',
			recivers: 'Кол-во',
			fact_order: "Факт. кол-во",
			sum: 'Сумма',
			paid: 'Оплачено',
			guide: 'Представитель',
			delivery_place: 'Место доставки',
			delivery_time: 'Дата доставки',
			return_place: 'Место возврата',
			return_time: 'Дата возврата',
		};
	};

	getArrayOfKeysByArrayOfObjects = (array, key) => {
		return array
			.map((el) => {
				if (el[key]) return el[key];
				else return null;
			})
			.filter((el) => el);
	};

	getObjectByKeyFromArraysOfObjects = (array, key, value) => {
		const object = array
			.map((el) => {
				if (el[key] && String(el[key]) === String(value))
					return { status: 'ok', data: el };
				return null;
			})
			.filter((el) => el);
		return object[0];
	};

	wrapPhoneByMask = (value) => {
		if (value === '+') return '';
		if (value.toString().length > 17) return value.slice(0, 17);
		let str = '';
		const re = /([0-9]{0,3})/;
		const num = /[0-9]/g;
		if (value) str = value.match(num);
		if (value === '+') str = '';
		if (str) {
			str = str.join('');
			str = str.replace(re, '+$1');
			return str;
		}

		return str;
	};

	wrapDateByMask = (value) => {
		if (value === '') return '';
		if (value.toString().length > 10) return value.slice(0, 10);
		let str = '';
		const re = /([0-9]{0,2})([0-9]{0,2})([0-9]{0,4})/;
		const num = /[0-9]/g;
		if (value) str = value.match(num);
		if (value === '') str = '';
		if (str) {
			str = str.join('');
			if (str.length < 3) {
				str = str.replace(re, '$1');
			} else if (str.length > 2 && str.length < 5) {
				str = str.replace(re, '$1.$2');
			} else str = str.replace(re, '$1.$2.$3');
			if (str.length === 10) {
				let day = str.slice(0, 2);
				if (parseInt(day) > 31) day = 31;
				if (parseInt(day) < 0) day = 0;
				let month = str.slice(3, 5);
				if (parseInt(month) > 12) month = 12;
				if (parseInt(month) < 0) month = 0;
				let year = str.slice(6, 10);
				if (parseInt(year) < 2000) year = 2000;
				str = day + '.' + month + '.' + year;
			}
			return str;
		}
		return '';
	};

	wrapTimeByMask = (value) => {
		if (value === '') return '';
		if (value.toString().length > 5) return value.slice(0, 5);
		let str = '';
		const re = /([0-9]{0,2})([0-9]{0,2})/;
		const num = /[0-9]/g;
		if (value) str = value.match(num);
		if (value === '') str = '';
		if (str) {
			str = str.join('');
			if (str.length < 3) {
				str = str.replace(re, '$1');
			} else {
				str = str.replace(re, '$1:$2');
			}
			if (str.length === 5) {
				let hour = str.slice(0, 2);
				if (parseInt(hour) > 23) hour = 23;
				if (parseInt(hour) < 0) hour = 0;
				let minute = str.slice(3, 5);
				if (parseInt(minute) > 59) minute = 59;
				if (parseInt(minute) < 0) minute = 0;
				str = hour + ':' + minute;
			}
			return str;
		}
		return '';
	};

	wrapDateTimeByMask = (value) => {
		// if (value === "") return "";
		// if (value.toString().length > 10)
		//   return value.slice(0, 10);
		let str = '';
		// const re = /([0-9]{0,2})([0-9]{0,2})([0-9]{0,4})/
		// const num = /[0-9]/g;
		// if (value) str = value.match(num);
		if (value === '') str = '';
		if (str) {
			// str = str.join('');
			// console.log(str);
			// if (str.length < 3) {
			//   str = str.replace(re, '$1');
			// }
			// else if (str.length > 2 && str.length < 5) {
			//   str = str.replace(re, '$1.$2');
			// }
			// else str = str.replace(re, '$1.$2.$3');
			// if (str.length === 10) {
			//   let day = str.slice(0, 2);
			//   if (parseInt(day) > 31)
			//     day = 31;
			//   if (parseInt(day) < 0)
			//     day = 0;
			//   let month = str.slice(3, 5);
			//   if (parseInt(month) > 12)
			//     month = 12;
			//   if (parseInt(month) < 0)
			//     month = 0;
			//   let year = str.slice(6, 10);
			//   if (parseInt(year) < 2000)
			//     year = 2000;
			//   str = day + "." + month + "." + year;
			// }
			// return str;
		}
		return '';
	};

	wrapNumberMask = (value) => {
		if (value === '') return '';
		let str = '';
		const num = /[0-9]/g;
		if (value) str = value.match(num);
		if (str) {
			str = str.join('');
			return str;
		}
		return '';
	};

	getStatusId = (data) => {
		if (data.canceled) {
			return 5;
		}
		if (data.canceled === false && data.status === 5) {
			return 101;
		}
		return data.status;
	};

	getStatusByOrderData(data) {
		let id = this.getStatusId(data);
		return this.getStatusByNum(id);
	}

	getStatusByNum(id) {
		switch (id) {
			case 0:
				return {
					title: 'Ожидает',
					class: 'awaiting',
					text: 'Заказ ожидает подтверждения сотрудником CS.',
				};
			case 1:
				return {
					title: 'Подтвержден',
					class: 'confirmed ',
					text: 'Заказ подтвержден и ждет начала работ по нему.',
				};
			case 2:
				return {
					title: 'В работе',
					class: 'inWork ',
					text:
						'Заказ в работе. Настал день выдачи заказа.  С этого момента заказ можно отменить только по телефону, в случае отмены, будут применены санкции согласно договора.',
				};
			case 3:
				return {
					title: 'Выполнен',
					class: 'completed',
					text: 'Заказ выполнен и ожидает оплату.',
				};
			case 4:
				return {
					title: 'Оплачен',
					class: 'paid',
					text: 'Заказ оплачен.',
				};
			case 5:
				return {
					title: 'Отменен',
					class: 'canceled',
					text:
						'Заказ отменен, действия со стороны менеджера клиента - запрещены. Часто осуществляют попытки удалить или что то в заказе поменять.',
				};
			case 6:
				return {
					title: 'Устарел',
					class: 'oldest',
					text:
						'Заказы старше 1.12.2018 - хранятся для истории, только на чтение, даже админам.',
				};
			case 7:
				return {
					title: 'Есть потери',
					class: 'THIS CLASS NOT WORKED',
					text:
						'Было утеряно некоторое количество передатчиков или приемников.',
				};
			case 101:
				return {
					title: 'Обрабатывается',
					class: 'inWork',
					text:
						'Данные переданы сотрудникам CrystalSound и заказ обрабатывается',
				};
			default:
				return {
					title: '#ERROR',
					class: 'error',
					text:
						'Сделайте скриншот и пришлите его в техподдержку. Приложите номер заявки.',
				};
		}
	}

	getCurrentDate() {
		const date = new Date();
		let day = date.getDate();
		let month = date.getMonth() + 1;
		const year = date.getFullYear();
		let hour = date.getHours();
		let minute = date.getMinutes();
		if (day < 10) day = '0' + day;
		if (minute < 10) minute = '0' + minute;
		if (hour < 10) hour = '0' + hour;
		if (month < 10) month = '0' + month;
		return day + '.' + month + '.' + year + ' ' + hour + ':' + minute;
	}

	getDateNextDay(nextDay = 0) {
		const selfDate = new Date();
		let dateBits = {
			year: selfDate.getFullYear(),
			month: selfDate.getMonth(),
			day: selfDate.getDate(),
			hours: selfDate.getHours(),
			minutes: selfDate.getMinutes(),
		};
		return new Date(
			dateBits.year,
			dateBits.month,
			dateBits.day + nextDay,
			dateBits.hours,
			dateBits.minutes
		);
	}

	checkExtraDay(date) {
		const testDate = new Date(date);
		const selfDate = new Date();
		let statusOrder = true;
		if (
			(selfDate.getHours() > 19 &&
				testDate.getDate() === selfDate.getDate() + 1) ||
			testDate.getDate() === selfDate.getDate()
		)
			if (
				window.confirm(
					'Ваш заказ является срочным, стоимость заказа будет увеличена в 1,5 раза'
				)
			)
				statusOrder = true;
			else statusOrder = false;

		return statusOrder;
	}

	thisIsDesctopVersion() {
		const mobileWidth = 630; //px
		if (document.documentElement.clientWidth > mobileWidth) {
			return true;
		} else {
			return false;
		}
	}

	getClassOrderCitySelection = (store) => {
		let baseClass = 'orderList_select';
		if (!this.checkChanceToPickCity(store)) baseClass += ' none';
		return baseClass;
	};

	checkChanceToPickCity = (store) => {
		const user = store.user;
		const canOrShow =
			!user.user_settings.can_pick_city ||
			!user.user_settings.show_num_city_selection;
		const allowesCitiesIsFill = store.allowedCities.length > 0;
		if (canOrShow && allowesCitiesIsFill && user.role > 1) {
			return false;
		}
		return true;
	};

	getClassOrderSelfNum = (store, selfclass = false) => {
		// let baseClass = "closeFilter mainInput_small pageContent_title-input";
		let baseClass = 'createOrder_input';
		if (selfclass) baseClass = selfclass;
		if (!this.checkChanceToPickSelfNum(store)) baseClass += ' none';
		return baseClass;
	};

	checkChanceToPickSelfNum = (store) => {
		if (!store.user.user_settings.manual_doc_num && store.user.role > 1) {
			return false;
		}
		return true;
	};

	getClassOrderItem = (store) => {
		if (
			this.checkChanceToPickCity(store) ||
			this.checkChanceToPickSelfNum(store)
		) {
			return 'createOrder_form-item';
		}
		return 'none';
	};

	getComponentProps = (props, badKeys) => {
		const goodPropsList = Object.keys(props);
		const newKeysList = without(badKeys, goodPropsList);
		const itemsList = newKeysList.map((el) => props[el]);
		return zipObj(newKeysList, itemsList);
	};

	getValue = (value) => {
		if (value) {
			return value;
		} else {
			return null;
		}
	};

	maskByDate = (
		date,
		setFunc,
		type = 'DateYMDHM',
		needCheckByExtraDay = false
	) => {
		if (date === null) {
			setFunc(null);
			return null;
		}
		if (needCheckByExtraDay) {
			const dateStatus = this.checkExtraDay(date);
			if (dateStatus) {
				setFunc(this.parseSepareteDate(date, '-', type));
			}
			return true;
		}
		setFunc(this.parseSepareteDate(date, '-', type));
		return true;
	};

	getApiUrl = () => {
		const host = window.location.host;
		if (host === 'my.crystal-sound.com') {
			return 'https://client.crystal-sound.com';
		}
		return 'https://test.crystal-sound.com';
	};
}
