import React, { useState } from 'react';
import mail from './iconMail.svg';
import phone from './iconPhone.svg';
import ButtonText from '../../buttons/ButtonText/ButtonText';
import TitleIcon from '../../texts/TitleIcon/TitleIcon';
import Input from '../../inputs/MainInput/MainInput';
import SideApps from '../../SideApps/SideApps';

const LogBar = (props) => {
	const [userName, setUserName] = useState();
	const [password, setPassword] = useState();
	const [statusLoad, setstatusLoad] = useState(false);

	const logIn = async () => {
		props.authentication(userName, password, setstatusLoad);
	};

	const onButtonClick = () => {
		setstatusLoad(true);
		logIn();
	};

	const onInputPress = (e) => {
		if (e.key === 'Enter') {
			setstatusLoad(true);
			onButtonClick();
		}
	};

	return (
		<div className='logBar'>
			<h1 className='logBar_logo'> CrystalSound</h1>
			<Input
				name='username'
				label='Логин'
				className='mb2'
				value={userName}
				changeValue={setUserName}
				onKeyPress={onInputPress}
			/>
			<Input
				name='password'
				type='password'
				label='Пароль'
				className='mb4'
				value={password}
				changeValue={setPassword}
				onKeyPress={onInputPress}
			/>
			<ButtonText
				className='logBar_button'
				type='primary'
				value='Вход'
				onClick={onButtonClick}
				loading={statusLoad}
			/>
			<p className='logBar_desription'>
				Для получения доступа в личный кабинет свяжитесь с нами по следующим
				контактам:
			</p>
			<SideApps className='mb2' />
			<div className='logBar_infoblock'>
				<TitleIcon
					margin='10px'
					icon={phone}
					size='18px'
					text='Телефон'
					color='lightdark'
					weight='700'
					className='logBar_titleIcon'
				/>
				<p className='logBar_info'>+7(812) 608-50-15</p>
			</div>
			<div className='logBar_infoblock'>
				<TitleIcon
					color='lightdark'
					margin='10px'
					weight='700'
					size='18px'
					icon={mail}
					text='E-mail'
					className='logBar_titleIcon'
				/>
				<p className='logBar_info'>hello@crystal-sound.com</p>
			</div>
		</div>
	);
};

export default LogBar;
