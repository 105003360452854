import React, { useContext } from 'react'
import ButtonCircle from '../buttons/ButtonCircle/ButtonCircle';
import Input from '../inputs/MainInput/MainInput';
import rAPService from '../../services/requestsApiDatabase.service';
import { Ctx } from '../../Store';


const FormNewMember = (props) => {
  const { store } = useContext(Ctx)

  const formData = props.formData;
  const formChangeData = props.formChangeData;
  const request = new rAPService({ url: store.apiUrl });


  console.log(formData);


  let classForm = `formNewMember-background`;
  if (props.active) {
    classForm += ` formNewMember--active`
  }

  const closeForm = () => {
    props.changeActive(false);
  }

  const getStaffArray = (tempStaff, array, isUpdate = true) => {
    let tempArray = [];
    array.forEach(staff => {
      if (staff.id === tempStaff.id) {
        if (isUpdate) {
          tempStaff.value = tempStaff.name.slice(0, 20);
          if (tempStaff.name.length > 20) tempStaff.value += "...";
          tempStaff.label = tempStaff.name + " " + tempStaff.phone_num;
          tempStaff.onClick = staff.onClick;
          tempArray = [...tempArray, tempStaff];
        }
      }
      else {
        tempArray = [...tempArray, staff];
      }
    })
    return tempArray;
  }


  const addStaff = () => {
    const staff = {
      staff: {
        id: formData.formMemberId,
        name: formData.formMemberName,
        client_id: formData.client_id,
        phone_num: formData.formPhone,
        email: formData.formEmail,
        guide: formData.formMemberType
      }
    }
    const answer = request.addStaff(staff, formData.formMemberId);
    answer.then(el => {
      if (el.status) {
        formChangeData.setMembers();
        let tempStaff = el.data.staff;
        if (tempStaff.guide) {
          formChangeData.setOrderGuidesArray(getStaffArray(tempStaff, formData.orderGuidesArray));
        } else {
          formChangeData.setOrderMenegersArray(getStaffArray(tempStaff, formData.orderMenegersArray));
        }
      }
    });
  }

  const deleteStaff = () => {
    if (window.confirm("Вы уверены что желаете удалить?")) {
      const staff = {
        id: formData.formMemberId,
        name: formData.formMemberName,
        client_id: formData.client_id,
        phone_num: formData.formPhone,
        email: formData.formEmail,
        guide: formData.formMemberType
      }
      const answer = request.deleteStaff(formData.formMemberId).then();
      answer.then(el =>
        el.json().then(
          statusRequest => {
            formChangeData.setMembers();
            if (statusRequest.status) {
              if (staff.guide) {
                formChangeData.setOrderGuidesArray(getStaffArray(staff, formData.orderGuidesArray, false));
              } else {
                formChangeData.setOrderMenegersArray(getStaffArray(staff, formData.orderMenegersArray, false));
              }
            }
          })
      );
    }
    closeForm();
  }

  const viewDelButton = () => {
    if (formData.formTitle.substring(0, 8) === "Добавить") {
      return null;
    } else {
      return <ButtonCircle onClick={deleteStaff} className="formNewMember_button formNewMember_hover--red" type="trash" />;
    }
  }


  return (
    <div className={classForm}>
      <div className="formNewMember">
        <h3>{formData.formTitle}</h3>
        <Input
          className="formNewMember_input"
          label="Имя"
          value={formData.formMemberName}
          onChange={formChangeData.setFormMemberName}
        />
        <Input
          className="formNewMember_input"
          label="Телефон"
          value={formData.formPhone}
          onChange={props.formChangeData.handleChangePhone}
        />
        <Input
          className="formNewMember_input"
          label="E-Mail"
          value={formData.formEmail}
          onChange={formChangeData.setFormEmail}
        />
        <div className="formNewMember_buttons">
          <ButtonCircle onClick={() => {
            addStaff();
            props.formChangeData.setMembers('');
            closeForm();
          }} className="formNewMember_button formNewMember_hover--green" type="save" />
          {viewDelButton()}
          <ButtonCircle onClick={closeForm} className="formNewMember_hover--gray" type="close" />
        </div>
      </div>
    </div>
  )
}

export default FormNewMember
