import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Ctx } from '../../../Store';
import authenticationService from '../../../services/authentication.service';

const MobileMenuList = props => {
	const { store, setUser } = useContext(Ctx);
	const auth = new authenticationService({
		url: store.apiUrl
	});

	const menuFields = store.translations.menu;

	let mobileListClass = 'mobileMenuList';
	if (props.activeMobileBurger) mobileListClass += ' mobileMenuList--active';
	const toogleMenu = () => {
		props.setActiveBurger(!props.activeMobileBurger);
	};
	const logout = () => {
		auth.logout();
		const emptyUser = {
			username: null
		}
		setUser(emptyUser);
	};

	if (!menuFields) return null;
	return (
		<div className={mobileListClass}>
			<ul className='mobileMenuList-ul'>
				<li onClick={toogleMenu}>
					<Link
						className='mobileMenuList_link mobileMenuList_link--active'
						to='/'
					>
						{menuFields.home}
					</Link>
				</li>
				<li onClick={toogleMenu}>
					<Link
						className='mobileMenuList_link mobileMenuList_link--active'
						to='/order-new'
					>
						{menuFields.order}
					</Link>
				</li>
				<li onClick={toogleMenu}>
					<Link
						className='mobileMenuList_link mobileMenuList_link--active'
						to='/order-list'
					>
						{menuFields.list}
					</Link>
				</li>
				<li>
					<Link className='mobileMenuList_link  disabled' to='/#'>
						{menuFields.calendar}
					</Link>
				</li>
				<li>
					<Link className='mobileMenuList_link disabled' to='/#'>
						{menuFields.help}
					</Link>
				</li>
				<li className="mobileMenuList_link" onClick={logout}>
					<Link className='mobileMenuList_link mobileMenuList_link-red' to='/#'>
						{menuFields.exit}
					</Link>
				</li>
			</ul>
		</div>
	);
};

export default MobileMenuList;
