import rAPService from "./requestsApiDatabase.service";

export default class authenticationService {
  constructor(props) {
    this.__apiUrl = props.url;
  }

  authRequest = (base64Code) => {
    const dbService = new rAPService({
      url: this.__apiUrl,
      base64: base64Code
    });
    let answer = dbService.getResource("/rapi/auth");
    answer.then(el => {
      if (el.status) {
        if (el.data.redirect) {
          localStorage.clear();
          window.location.replace(el.data.redirect);
          return null;
        }
        localStorage.setItem("baseCode", base64Code);
      }
    });
    return answer;
  }

  loginByPassword = (userName, password) => {
    const base64Code = "Basic " + window.btoa(userName + ":" + password);
    return this.authRequest(base64Code)
  };

  loginByBaseCode = base64Code => {
    return this.authRequest(base64Code)
  };

  logout = () => {
    localStorage.removeItem("baseCode");
  };
}
