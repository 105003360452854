import React, { useState, useContext } from 'react'
import convertDataService from '../../services/conversionData.service';
import SelectMain from '../../components/selects/SelectMain/SelectMain';
import ButtonIconUser from '../../components/buttons/ButtonIconUser/ButtonIconUser';
import FormNewMember from '../../components/FormNewMember/FormNewMember';
import Loader from 'react-spinners/RingLoader';
import { Ctx } from '../../Store';
import rAPService from '../../services/requestsApiDatabase.service';
import ButtonText from '../../components/buttons/ButtonText/ButtonText';
import { useRouteMatch, Redirect } from 'react-router-dom';
import StatusViwer from '../../components/StatusViwer/StatusViwer';
import ChangeState from '../../components/ChangeState/ChangeState';
import TextArea from '../../components/TextArea/TextArea';
import MainDatePicker from '../../components/MainDatePicker/MainDatePicker';
import Input from '../../components/inputs/MainInput/MainInput';
import editOrderUnit from './editOrder.unit';
import checkValidationServise from '../../services/checkValidation.servise';
import CreatableSelect from '../../components/selects/CreatableSelect/CreatableSelect';

const EditOrder = () => {
  const { store } = useContext(Ctx);
  const cDS = new convertDataService();
  const unit = new editOrderUnit();
  const request = new rAPService({ url: store.apiUrl });
  const validService = new checkValidationServise();
  const match = useRouteMatch();
  const [redirect, setRedirect] = useState();

  //Данные документа и статус
  const [orderData, setOrderData] = useState();
  const [stateOrder, setStateOrder] = useState('view');

  //Данные формы
  const [isActiveForm, setIsActiveForm] = useState(false);
  const [formMemberType, setFormMemberType] = useState('');
  const [formTitle, setFormTitle] = useState('');
  const [formMemberName, setFormMemberName] = useState('');
  const [formPhone, setFormPhone] = useState('');
  const [formEmail, setFormEmail] = useState('');
  const [formMemberId, setFormMemberId] = useState();

  //Список возможных гидов
  const [members, setMembers] = useState();

  //список мест возврата и доставок
  const [placesDaR, setPlacesDaR] = useState(null);

  // Данные списка заказов
  const [orderInit, setOrderInit] = useState(false);
  const [orderSelfNum, setOrderSelfNum] = useState('');
  const [orderCity, setOrderCity] = useState('');
  const [orderDeliveryDate, setOrderDeliveryDate] = useState('');
  const [orderDeliveryPlace, setOrderDeliveryPlace] = useState('');
  const [orderReturnDate, setOrderReturnDate] = useState('');
  const [orderReturnPlace, setOrderReturnPlace] = useState('');
  const [orderAmountReceivers, setOrderAmountReceivers] = useState('')
  const [orderAmountTransmitters, setOrderAmountTransmitters] = useState('');
  const [orderExtraAmountReceivers, setOrderExtraAmountReceivers] = useState('');
  const [orderGuidesArray, setOrderGuidesArray] = useState([]);
  const [orderMenegersArray, setOrderMenegersArray] = useState([]);
  const [orderComment, setOrderComment] = useState('');

  //отображение валидации
  const [viewValidation, setviewValidation] = useState(false);

  const loader = (
    <section className="createOrder">
      <div>
        <div className="createOrder_form createOrder_center">
          <Loader size={300} color={"blue"} loading={true} />
        </div>
      </div>
    </section>
  )

  if (redirect)
    return <Redirect to={redirect} />

  if (!placesDaR) {
    request.getPlacesDaR(store.user.user_settings.client_id).then(el => setPlacesDaR(el));
    return loader;
  }


  if (!orderData) {
    request.getOrderDocument(match.params.id).then(el => {
      if (el.status) {
        setOrderData(el.data.order)
      } else {
        setRedirect("/notFound");
      }
    });
    return loader;
  }



  //проверка на возможность изменения/отображения полей города и номера
  const classOrderSelfNum = cDS.getClassOrderSelfNum(store);
  const orderStatus = cDS.getStatusByOrderData(orderData);


  //функции связанные с созданием нового сотрудника
  const clearMemberForm = () => {
    setFormTitle('Форма сотрудника');
    setFormMemberName('');
    setFormPhone('');
    setFormEmail('');
    setFormMemberType('');
    setFormMemberId('');
  }
  const handleChangePhone = (phone) => {
    const newPhone = cDS.wrapPhoneByMask(phone);
    if (newPhone)
      setFormPhone(newPhone);
  }
  const openFormNewGuide = () => {
    clearMemberForm();
    setFormTitle('Добавить гида');
    setFormMemberType(true);
    setIsActiveForm(true);
  };
  const openFormNewManager = () => {
    clearMemberForm();
    setFormTitle('Добавить менеджера');
    setFormMemberType(false);
    setIsActiveForm(true);
  };
  const memberClick = (member) => {
    setFormTitle('Редактировать ' + member.name);
    setFormMemberName(member.name);
    handleChangePhone(member.phone_num);
    setFormEmail(member.email);
    setFormMemberId(member.id);
    setFormMemberType(member.guide);
    setIsActiveForm(true);
  }
  //функции которые мы передает в форму нового сотрудника
  const formChangeData = {
    setFormMemberName,
    handleChangePhone,
    setFormEmail,
    setMembers,
    setOrderGuidesArray,
    setOrderMenegersArray
  }
  //данные которые мы пеердаем в форму нового сотрудника
  const formData = {
    client_id: orderData.client_id,
    formMemberType,
    formTitle,
    formMemberName,
    formPhone,
    formEmail,
    formMemberId,
    orderGuidesArray,
    orderMenegersArray
  }

  const getOrderDataForRequest = (employee_id, manager_id, type) => {
    let order = null;
    if (type === "edit") {
      order = {
        order: {
          idorders: orderData.idorders,
          client_id: orderData.client_id,
          delivery_time: orderDeliveryDate + ":00",
          delivery_place: orderDeliveryPlace,
          return_time: orderReturnDate + ":00",
          return_place: orderReturnPlace,
          recivers: orderAmountReceivers,
          transmitters: orderAmountTransmitters,
          extra_recivers: orderExtraAmountReceivers,
          employee_id: employee_id,
          manager_id: manager_id,
          extra_service: orderComment,
          city: orderCity.id
        }
      };
    } else if (type === "copy") {
      order = {
        order: {
          client_id: orderData.client_id,
          delivery_time: orderDeliveryDate + ":00",
          delivery_place: orderDeliveryPlace,
          return_time: orderReturnDate + ":00",
          return_place: orderReturnPlace,
          recivers: orderAmountReceivers,
          transmitters: orderAmountTransmitters,
          extra_recivers: orderExtraAmountReceivers,
          employee_id: employee_id,
          manager_id: manager_id,
          extra_service: orderComment,
          city: orderCity.id
        }
      };
    }
    if (store.user.user_settings.can_pick_city)
      order.order.city = orderCity.id;
    if (store.user.user_settings.manual_doc_num)
      order.order.manualnumber = orderSelfNum;
    return order;
  }

  const getIdxFromArray = (array) => {
    if (array) {
      return array.map(el => el.id);
    }
    return null;
  }


  const checkValidInputs = () => {
    if (validService.checkValidMember(orderGuidesArray).validated &&
      validService.checkValidMember(orderMenegersArray).validated &&
      validService.checkValidCity(orderCity).validated &&
      validService.checkValidPlace(orderDeliveryPlace).validated &&
      validService.checkValidPlace(orderReturnPlace).validated &&
      validService.checkValidNumByDevise(orderAmountReceivers).validated &&
      validService.checkValidNumByDevise(orderAmountTransmitters).validated &&
      validService.checkValidNumByDevise(orderExtraAmountReceivers).validated &&
      validService.checkValidDate(orderDeliveryDate).validated &&
      validService.checkValidDate(orderReturnDate).validated)
      return true
    else
      return false
  }

  let buttonErrorAnimation = false;
  if (!checkValidInputs()) {
    buttonErrorAnimation = true;
  }

  //Отправка данных на сервер
  const pushOrder = () => {
    if (stateOrder === "view") {
      alert("Нельзя редактировать или создавать данные в режими просмотра");
      return null;
    }
    const employee_id = getIdxFromArray(orderGuidesArray);
    const manager_id = getIdxFromArray(orderMenegersArray);
    if (checkValidInputs()) {
      const order = getOrderDataForRequest(employee_id, manager_id, stateOrder, store);
      const newRequest = unit.getNewRequest(order, request, stateOrder);
      newRequest.then(el => {
        if (el.ok) {
          if (stateOrder === "edit") {
            alert("Данные успешно обновлены");
          }
          else {
            el.json().then(answer => {
              const id = answer.data.order.idorders;
              setRedirect("/order-created/" + id);
            });
          }
        } else {
          alert("Во время отправки данных произошла ошибка");
        }
      })
    } else {
      setviewValidation(true);
    }
  }



  const canselOrder = () => {
    if (window.confirm("Вы уверены что хотите отменить заказ? Решения изменить будет нельзя, только создать новый заказ копированием.")) {
      const order = {
        order: {
          idorders: orderData.idorders,
          client_id: orderData.client_id,
          delivery_time: orderData.delivery_time,
          delivery_place: orderData.delivery_place,
          return_time: orderData.return_time,
          return_place: orderData.return_place,
          recivers: orderData.recivers,
          transmitters: orderData.transmitters,
          extra_recivers: orderData.extra_recivers,
          employee_id: orderData.employee_id,
          manager_id: orderData.manager_id,
          extra_service: orderData.extra_service,
          city: orderData.city,
          canceled: true
        }
      };
      if (store.user.user_settings.can_pick_city)
        order.order.city = orderCity.id;
      if (store.user.user_settings.manual_doc_num)
        order.order.manualnumber = orderSelfNum;
      let newRequest = request.addOrder(order, 'put');
      newRequest.then(el => {
        if (el.ok) {
          alert("Заказ отменен");
        } else {
          alert("Во время отправки данных произошла ошибка");
        }
      })
    }
  }


  if (!members) {
    request.getGuidesAndMenegers(orderData.client_id).then(el => setMembers(el));
    return loader;
  }

  //проверка на ошибку записи гидов
  if (members.guides[1])
    if (members.guides[0].id === members.guides[1].id) {
      return loader;
    }
  if (members.menegers[1])
    if (members.menegers[0].id === members.menegers[1].id) {
      return loader;
    }

  //создание массиов id
  const guidesId = unit.getGuidesId(members.guides);
  const menegersId = unit.getMenegersId(members.menegers);

  const clearInpustToCopy = () => {
    setOrderSelfNum(null);
    setOrderDeliveryDate(null);
    setOrderReturnDate(null);
  }

  const fillInputsToView = () => {
    setOrderSelfNum(orderData.manualnumber);
    setOrderDeliveryDate(cDS.parseSepareteDate(orderData.delivery_time, '-', 'DateYMDHM'));
    setOrderReturnDate(cDS.parseSepareteDate(orderData.return_time, '-', `DateYMDHM`));
  }

  const redirectToOrderList = () => {
    setRedirect("/order-list");
  }

  //Отображение изменения состояния
  const getRenderButtonsOfState = () => {
    switch (stateOrder) {
      case "view":
        return <>
          <ChangeState disabled={orderData.canceled} type="edit" setStatusItem={setStateOrder} />
          <ChangeState onClick={clearInpustToCopy} type="copy" setStatusItem={setStateOrder} />
          <ChangeState onClick={redirectToOrderList} type="toList" />
        </>
      case "edit":
        return <>
          <ChangeState type="view" setStatusItem={setStateOrder} />
          <ChangeState onClick={clearInpustToCopy} setStatusItem={() => { }} type="copy" disabled />
          <ChangeState onClick={redirectToOrderList} type="toList" />
        </>
      case "copy":
        return <>
          <ChangeState onClick={fillInputsToView} type="back" setStatusItem={setStateOrder} />
          <ChangeState onClick={redirectToOrderList} type="toList" />
        </>
      default:
        break;
    }
  }

  const buttosChangeState = getRenderButtonsOfState();

  const isDisabledByView = unit.getIsDisabledByView(stateOrder, orderData.canceled);

  //заполнение массива гидов, проверка на int или массив который object
  const fillArrayOfMembers = (item, type) => {
    if (typeof (item) === "number") {

      if (type === "guides") {
        guidesId.forEach((id, idx) => {
          if (id === item) {
            setOrderGuidesArray([{
              id: id,
              name: members[type][idx].name,
              client_id: members[type][idx].client_id,
              phone_num: members[type][idx].phone_num,
              email: members[type][idx].email,
              guide: members[type][idx].guide,
              value: members[type][idx].value,
              label: members[type][idx].label,
              onClick: memberClick
            }]);
          }
        })
      }
      if (type === "menegers") {
        menegersId.forEach((id, idx) => {
          if (id === item) {
            setOrderMenegersArray([{
              id: id,
              name: members[type][idx].name,
              client_id: members[type][idx].client_id,
              phone_num: members[type][idx].phone_num,
              email: members[type][idx].email,
              guide: members[type][idx].guide,
              value: members[type][idx].value,
              label: members[type][idx].label,
              onClick: memberClick
            }]);
          }
        })
      }
    } else {
      let tempArray = [];
      members[type].forEach(el => {
        if (item.indexOf(el.id) > -1) {
          tempArray = [...tempArray, {
            id: el.id,
            name: el.name,
            client_id: el.client_id,
            phone_num: el.phone_num,
            email: el.email,
            guide: el.guide,
            value: el.value,
            label: el.label,
            onClick: memberClick
          }];
        }
      });
      if (type === "guides")
        setOrderGuidesArray(tempArray);
      else setOrderMenegersArray(tempArray);
    }
  }


  //Функции связанные с инпутами данных заказа
  const maskForOrderDeliveryDate = (value) => {
    if (value === null) {
      setOrderDeliveryDate(null);
      return null
    }
    const dateStatus = cDS.checkExtraDay(value);
    if (dateStatus)
      setOrderDeliveryDate(cDS.parseSepareteDate(value, '-', 'DateYMDHM'));
  }
  const maskForOrderReturnDate = (value) => {
    if (value === null) {
      setOrderReturnDate(null);
      return null
    }
    setOrderReturnDate(cDS.parseSepareteDate(value, '-', 'DateYMDHM'));
  }
  const maskForOrderAmountReceivers = value => {
    setOrderAmountReceivers(cDS.wrapNumberMask(value));
  }
  const maskForOrderAmountTransmitters = value => {
    setOrderAmountTransmitters(cDS.wrapNumberMask(value));
  }
  const maskForOrderExtraAmountReceivers = value => {
    setOrderExtraAmountReceivers(cDS.wrapNumberMask(value))
  }
  const handleSetOrderSity = (id) => {
    let answer = null;
    store.allowedCities.forEach((el) => {
      if (id === Number(el.id)) {
        answer = el;
      }
    });
    return answer;
  }

  if (parseInt(orderAmountTransmitters) > parseInt(orderExtraAmountReceivers))
    setOrderExtraAmountReceivers(orderAmountTransmitters);
  let isDisabledOrderExtraAmountReceivers = false;
  if (store.user.role > 1)
    isDisabledOrderExtraAmountReceivers = true;

  //запись данных в формы с сервера, делается один раз
  if (!orderInit) {
    setOrderSelfNum(orderData.manualnumber);
    setOrderCity(handleSetOrderSity(orderData.city));
    setOrderDeliveryDate(cDS.parseSepareteDate(orderData.delivery_time, '-', 'DateYMDHM'));
    setOrderDeliveryPlace(orderData.delivery_place);
    setOrderReturnDate(cDS.parseSepareteDate(orderData.return_time, '-', `DateYMDHM`));
    setOrderReturnPlace(orderData.return_place);
    setOrderAmountReceivers(orderData.recivers);
    setOrderAmountTransmitters(orderData.transmitters);
    setOrderComment(orderData.extra_service);
    setOrderExtraAmountReceivers(orderData.extra_recivers);
    fillArrayOfMembers(orderData.employee_id, "guides");
    fillArrayOfMembers(orderData.manager_id, "menegers");
    setOrderInit(true);

    if (match.params.type === "copy") {
      setStateOrder("copy");
    }
  }

  const textPushButton = unit.getTextPushButton(stateOrder);
  const textOrderTitle = unit.getTextOrderTitle(orderData.idorders, orderData.manualnumber, cDS.parseSepareteDate(orderData.date, '.', 'DateDMY'), stateOrder);

  let minDate = null;
  if (typeof (orderData.date) === "string") {
    minDate = orderData.date.replace(/\s/, 'T');
  }

  //дата возврата должна быть больше даты возварата
  if (new Date(orderDeliveryDate) > new Date(orderReturnDate)) {
    setOrderReturnDate(cDS.parseSepareteDate(new Date(orderDeliveryDate), '-', 'DateYMDHM'));
  }


  return (
    <section className="createOrder">
      <div>
        <div className="createOrder_title">
          <div className="createOrder_title-left">
            <h2>Заказ</h2>
            <div className="createOrder_title-left--monoLine">
              {buttosChangeState}
            </div>
          </div>
          <div className="createOrder_title-right">
            <span className="createOrder_title-date ">
            </span>
          </div>
        </div>
        <div className="createOrder_form">
          <FormNewMember formData={formData} formChangeData={formChangeData} active={isActiveForm} changeActive={setIsActiveForm} />
          <div className={isActiveForm ? "none" : ""}>
            <div className="createOrder_info">
              <div className="createOrder_info-man none">
                <figure>
                  <img src="https://upload.wikimedia.org/wikipedia/commons/4/48/Outdoors-man-portrait_%28cropped%29.jpg" alt="" />
                </figure>
                <div>
                  <h3>Курьер</h3>
                  <span>Михаил Матвеевич</span>
                </div>
              </div>
              <div className="createOrder_info-status">
                <h3>Статус заказа</h3>
                <StatusViwer type={orderStatus.class} title={orderStatus.title} text={orderStatus.text} />
              </div>
            </div>
            <div className="createOrder_form-item">
              <div className="createOrder_form-item--title">
                <i className="fal fa-box-full red"></i>
                <h3>{textOrderTitle}</h3>
              </div>
              <div className="createOrder_form-item--content">
                <div className="createOrder_form-item--content_partFirst">
                  <Input
                    className="createOrder_input createOrder_leftInput none"
                    label="Номер"
                    value={orderData.idorders}
                    disabled={true}
                  />
                  <Input
                    className={classOrderSelfNum} label="Свой номер" copy value={orderSelfNum} changeValue={setOrderSelfNum} disabled={isDisabledByView} />
                </div>
                <div className="createOrder_form-item--content_partSecond">
                  <SelectMain
                    placeholder="Выбрать город"
                    isSearchable={false}
                    isDisabled={isDisabledByView}
                    items={store.allowedCities}
                    value={orderCity}
                    changeValue={setOrderCity}
                    validation={validService.checkValidCity}
                    viewValidation={viewValidation}
                  />
                </div>
              </div>
            </div>
            <div className="createOrder_form-item">
              <div className="createOrder_form-item--title">
                <i className="fal fa-truck blue"></i>
                <h3>Доставка</h3>
              </div>
              <div className="createOrder_form-item--content">
                <div className="createOrder_form-item--content_partFirst">
                  <MainDatePicker
                    disabled={isDisabledByView}
                    selected={orderDeliveryDate}
                    placeholderText="Дата доставки"
                    minDate={minDate}
                    onChange={maskForOrderDeliveryDate}
                    validation={validService.checkValidDate}
                    viewValidation={viewValidation}
                  />
                </div>
                <div className="createOrder_form-item--content_partSecond">
                  <CreatableSelect
                    isSearchable={true}
                    value={orderDeliveryPlace}
                    changeValue={setOrderDeliveryPlace}
                    items={placesDaR.placesDelivery}
                    isCreatable={true}
                    validation={validService.checkValidPlace}
                    viewValidation={viewValidation}
                    personalValue={true}
                    label="Место доставки"
                    disabled={isDisabledByView}
                  />
                </div>
              </div>
            </div>
            <div className="createOrder_form-item">
              <div className="createOrder_form-item--title">
                <i className="fal fa-parachute-box purple"></i>
                <h3>Возврат</h3>
              </div>
              <div className="createOrder_form-item--content">
                <div className="createOrder_form-item--content_partFirst">
                  <MainDatePicker
                    disabled={isDisabledByView}
                    selected={orderReturnDate}
                    placeholderText="Дата возврата"
                    minDate={minDate}
                    onChange={maskForOrderReturnDate}
                    validation={validService.checkValidDate}
                    viewValidation={viewValidation}
                  />
                </div>
                <div className="createOrder_form-item--content_partSecond">
                  <CreatableSelect
                    label="Место возврата"
                    isSearchable={true}
                    items={placesDaR.placesReturn}
                    value={orderReturnPlace}
                    changeValue={setOrderReturnPlace}
                    isCreatable={true}
                    validation={validService.checkValidPlace}
                    viewValidation={viewValidation}
                    personalValue={true}
                    disabled={isDisabledByView}
                  />
                </div>
              </div>
            </div>
            <div className="createOrder_form-amount">
              <div className="createOrder_form-amount-partFirst">
                <div className="createOrder_form-item--title">
                  <i className="fal fa-boxes-alt yellow"></i>
                  <h3>Количество</h3>
                </div>
                <div className="createOrder_form-amount-partFirst_content">
                  <Input
                    className="createOrder_input createOrder_leftInput"
                    label="Приемников"
                    value={orderAmountReceivers}
                    changeValue={maskForOrderAmountReceivers}
                    disabled={isDisabledByView}
                    validation={validService.checkValidNumByDevise}
                    viewValidation={viewValidation}
                  />
                  <Input
                    value={orderAmountTransmitters}
                    changeValue={maskForOrderAmountTransmitters} className="createOrder_input"
                    label="Передатчиков"
                    disabled={isDisabledByView}
                    validation={validService.checkValidNumByDevise}
                    viewValidation={viewValidation}
                  />
                </div>
              </div>
              <div className="createOrder_form-amount-PartSecond">
                <div className="createOrder_form-item--title">
                  <i className="fal fa-puzzle-piece yellow"></i>
                  <h3>Дополнительно</h3>
                </div>
                <div className="createOrder_form-amount-PartSecond_content">
                  <Input
                    className="createOrder_input"
                    label="Приемников"
                    value={orderExtraAmountReceivers}
                    changeValue={maskForOrderExtraAmountReceivers}
                    disabled={isDisabledByView ? isDisabledByView : isDisabledOrderExtraAmountReceivers}
                  />
                </div>
              </div>
            </div>
            <div className="createOrder_form-item">
              <div className="createOrder_form-item--title">
                <i className="fal fa-user-ninja red"></i>
                <h3>Гид</h3>
              </div>
              <div className="createOrder_form-secondStepItem">
                <SelectMain
                  isMulti={true}
                  value={orderGuidesArray}
                  changeValue={setOrderGuidesArray}
                  items={members.guides}
                  onItemClick={memberClick}
                  className="createOrder_multiSelect"
                  isDisabled={isDisabledByView}
                  validation={validService.checkValidMember}
                  viewValidation={viewValidation}
                />
                <ButtonIconUser action={openFormNewGuide} disabled={isDisabledByView} />
              </div>
            </div>
            <div className="createOrder_form-item">
              <div className="createOrder_form-item--title">
                <i className="fal fa-user-tie blue"></i>
                <h3>Менеджер</h3>
              </div>
              <div className="createOrder_form-secondStepItem">
                <SelectMain
                  isMulti={true}
                  value={orderMenegersArray}
                  changeValue={setOrderMenegersArray}
                  items={members.menegers}
                  onItemClick={memberClick}
                  className="createOrder_multiSelect"
                  isDisabled={isDisabledByView}
                  validation={validService.checkValidMember}
                  viewValidation={viewValidation}
                />
                <ButtonIconUser action={openFormNewManager} disabled={isDisabledByView} />
              </div>
            </div>
            <div className="createOrder_form-item">
              <div className="createOrder_form-item--title">
                <i className="fal fa-puzzle-piece yellow"></i>
                <h3>Дополнительно</h3>
              </div>
              <div className="createOrder_form-secondStepItem">
                <TextArea disabled={isDisabledByView} text={orderComment} changeText={setOrderComment} />
              </div>
            </div>
            <div className="createOrder_secondStep-buttons">
              <ButtonText
                className="createOrder_secondStep-buttons-button"
                type="danger"
                onClick={() => { canselOrder() }}
                value="Отменить заказ"
                disabled={isDisabledByView}
              />
              <ButtonText
                className="createOrder_secondStep-buttons-button"
                onClick={() => { pushOrder() }}
                type="primary"
                value={textPushButton}
                errrorAnimation={buttonErrorAnimation}
                disabled={isDisabledByView}
              />
            </div>
          </div>
        </div>
      </div>
    </section >
  )
}

export default EditOrder
