export default class selectMainUnit {
  getOptions = (props) => {
    return props.items.map(el => {
      el.disabled = props.isDisabled
      if (props.onItemClick)
        el.onClick = props.onItemClick;
      return el;
    });
  }

  getSelftClass = (className) => {
    if (className) {
      return "selectMain " + className;
    }
    return "selectMain";
  }

  getDefaultValue = (props, options) => {
    let defaultValue = null;
    if (props.defaultValue)
      options.forEach((el, idx) => {
        if (props.defaultValue === el.id)
          defaultValue = idx
      }
      );
    return defaultValue;
  }

  getPlaceholder = (placeholder) => {
    if (placeholder)
      return placeholder;
    return "Выбрать";
  }

  getView = (selfClass, view) => {
    if (view) {
      return selfClass + ' selectMain_' + view;
    }
    return selfClass + ' selectMain_normal';
  }
}